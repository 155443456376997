import { Injectable } from '@angular/core';
import { Submission } from '../class/submission';

@Injectable({
  providedIn: 'root'
})
export class CatexService {

  constructor() { }

  public evaluateSubmission(sub: Submission): Object {

    // if single procedure
    if (sub.isSingle) {

      // fail the screening in the case of an airway
      if (sub.airportCode == 'Airway') {
        return ['Review', 'EPS must confirm no extraordinary circumstances exist.'];
      }
      
      // if amended and field only change
      if (sub.procedureRequestType == 'Amended' && sub.isOnlyChangesToProcedure) {
        return ['CATEX', '5-6.5.k', 'Amended procedure and no disqualifying changes.'];
      }

      // if above 18k
      if (sub.procedureAltitude == 'Above 18,000 feet AGL') {
        return ['CATEX', '5-6.5.i', 'Procedure altitude > 18,000ft AGL.'];
      }

      // if below 18k
      // if unknown noise area
      if (sub.isUnknown) {
        return ['Review', '"Unknown" noise sensitive area and < 18,000ft AGL.'];
      }

      // if national park
      if (sub.isNationalPark || sub.isNationalWilderness || sub.isNationalWildlifeRefuge) {
        return ['Review', 'Arrival or Departure over National Park, Wilderness Area or Refuge with altitude < 18,000ft AGL'];
      }

      var isNoiseSensitiveArea = sub.isResidential || sub.isEducational || sub.isHospital || sub.isReligiousStructure || sub.isParkNotNational || sub.isRecreational;
      // if not noise sensitive area
      if (!isNoiseSensitiveArea && sub.procedureRequestType == 'Amended') {
        return ['CATEX', '5-6.5.i', 'Not over a noise sensitive area.'];
      } 

      if (!isNoiseSensitiveArea) {
        return ['CATEX', '5-6.5.p', 'Not over a noise sensitive area.'];
      } 

      // if noise sensitive area
      // if helo only
      if (sub.isOnlyHelicopterOps) {
        // if emergency helo
        if (sub.isEmergencyHeloRoute) {
          return ['CATEX', '5-6.5.j', 'Emergency helicopter procedure.']
        }

        // weighted helo opss
        var heloOps = sub.numHelicopterDayOps + sub.numHelicopterEveningOps * 5 + sub.numHelicopterNightOps * 10;

        // if helo ops <= 10 daily
        if (heloOps <= 365 * 10 && sub.procedureRequestType == 'Amended') {
          return ['CATEX', '5-6.5.i', 'No more than 10 daily/3650 annual helicopter operations on route.'];
        }

        if (heloOps <= 365 * 10) {
          return ['CATEX', '5-6.5.p', 'Not over a noise sensitive area.'];
        }

        // major thoroughfare???

      } else {
        // if not helo only
        var result = this.operationsTest(sub);

        // if passed operations test
        if (result[0]) {
          return ['CATEX', '5-6.5.i', 'Passed operations test. ' + result[1]];
        } else {
          return ['Review', result[1]];
        }
      }

      return ['Review', 'Further review required due to no qualifying CATEX.'];

    } else {
      // if multiple procedures

      // fail the screening in the case of an airway
      if (sub.airportCode == 'Airway') {
        return ['Review', 'EPS must confirm no extraordinary circumstances exist.'];
      }
      
      // if new procedures
      if (sub.procedureRequestType == 'New') {
        return ['Review', 'Multiple procedures for New Procedure Request.'];
      }

      // if amended procedures
      if (sub.isOnlyChangesToProcedure) {
        return (['CATEX', '5-6.5.k', 'Amended procedure and no disqualifying changes.']);
      }

      return ['Review', 'Multiple procedures for Amended Procedure Request with disqualifying changes.'];
    }
  }

  private operationsTest(sub: Submission) {
    // weighted ops counts
    var propOps = sub.numPropsDayOps + sub.numPropsEveningOps * 5 + sub.numPropsNightOps * 10;
    var jetOps = sub.numJetsDayOps + sub.numJetsEveningOps * 5 + sub.numJetsEveningOps * 10;
    var heloOps = sub.numHelicopterDayOps + sub.numHelicopterEveningOps * 5 + sub.numHelicopterNightOps * 10;

    // get altitudes
    var alt = sub.procedureAltitude;
    var minAlt;
    var maxAlt;
    if (alt.startsWith('Surface')) {
      minAlt = 0;
      maxAlt = 3000;
    } else if (alt.startsWith('3,001')) {
      minAlt = 3001;
      maxAlt = 7000;
    } else if (alt.startsWith('7,001')) {
      minAlt = 7001;
      maxAlt = 10000;
    } else if (alt.startsWith('10,001')) {
      minAlt = 10001;
      maxAlt = 18000;
    } else {
      minAlt = 18000;
      maxAlt = 99999;
    }

    // No helo, either props or jets only
    if (heloOps == 0 && (jetOps == 0 || propOps == 0)) {
      // if arrivals          
      if (sub.isApproachProcedure) {
        if (minAlt > 7000) {
          return [true, 'Arrival, Alt > 7,000ft AGL.'];
        }
        if (maxAlt <= 7000 && propOps <= 90000 && jetOps == 0) {
          return [true, 'Arrival, Alt <= 7,000ft, only props <= 90000 annual ops.'];
        }
        if (maxAlt <= 7000 && propOps == 0 && jetOps <= 700) {
          return [true, 'Arrival, Alt <= 7,000ft AGL, only jets <= 700 annual ops.'];
        }
      } else {
        // if departure or enroute
        if (minAlt > 10000) {
          return [true, 'Departure/Enroute, Alt > 10,000ft AGL.'];
        }
        if (maxAlt <= 10000 && jetOps == 0 && propOps <= 90000) {
          return [true, 'Departure/Enroute, Alt <= 10,000ft AGL, only props <= 90000 annual ops.'];
        }
        if (maxAlt <= 10000 && propOps == 0 && jetOps <= 700) {
          return [true, 'Departure/Enroute, Alt <= 10,000ft AGL, only jets <= 700 annual ops.'];
        }
      }
    } else {
      // more than one AC type
      // if arrivals
      if (sub.isApproachProcedure) {
        if (maxAlt <= 7000 && propOps <= 81000 && jetOps == 0 && heloOps <= 365) {
          return [true, 'Arrival, Alt <= 7000, props <= 81000 and helo <= 365 annual ops.'];
        }
        if (maxAlt <= 7000 && propOps <= 36000 && jetOps <= 365 && heloOps <= 365) {
          return [true, 'Arrival, Alt <= 7000, props <= 36000, jets <= 365 and helos <= 365 annual ops.'];
        }
      } else {
        // if departure or enroute
        if (maxAlt < 10000 && propOps == 0 && jetOps <= 365 && heloOps <= 1800) {
          return [true, 'Arrival, Alt <= 10000, only jets <= 365 and help <= 1800 annual ops.'];
        }
        if (maxAlt < 10000 && propOps <= 45000 && jetOps <= 365 && heloOps == 1800) {
          return [true, 'Arrival, Alt <= 10000, props <= 45000 and jets <= 365 annual ops.'];
        }
      }
    }

    return [false, 'Failed operations test.'];
  }
}
