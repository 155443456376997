import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/service/user.service';
import { AirportService } from 'app/shared/service/airport.service';
import { User } from '../shared/class/user';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  users: User[];
  user: User;

  cols: Array<any> = [
    { field: 'firstName', header: 'First Name', width: '12.5%' },
    { field: 'lastName', header: 'Last Name', width: '12.5%' },
    { field: 'email', header: 'Email', width: '25%' },
    { field: 'rolesStr', header: 'Roles', width: '25%' },
    { field: 'serviceCenter', header: 'Service Center', width: '12%' },
    { field: 'airport', header: 'Airport', width: '8%' }
  ];

  rolesDisplay: Array<any> = [
    { type: 'ProcedureDesigner', str: 'Procedure Designer' },
    { type: 'AirportContact', str: 'Airport Contact' },
    { type: 'EnvironmentalSpecialist', str: 'Environmental Specialist' },
    { type: 'PortalAdmin', str: 'Portal Administrator' },
    { type: 'ServiceCenterAdmin', str: 'Service Center Administrator' },
    { type: 'ServiceCenterManager', str: 'Service Center Manager' },
    { type: 'ExternalSubmissionsAdmin', str: 'External Submissions Administrator' },
    { type: 'ExternalSubmissionsManager', str: 'External Submissions Manager' },
    { type: 'IFPAdmin', str: 'IFP Administrator' },
    { type: 'IFPManager', str: 'iFP Manager' }
  ]

  serviceCenters: Array<any> = [
    { str: 'Central', code: 'C', id: 1 },
    { str: 'Eastern', code: 'E', id: 2 },
    { str: 'Western', code: 'W', id: 3 },
    { str: 'AFS', code: 'A', id: 4 },
    { str: 'External', code: 'X', id: 5 }
  ]

  rowCount: number = 10;
  entries: Array<any> = [
    { val: 10 },
    { val: 25 },
    { val: 50 },
    { val: 100 }
  ];

  isPortalAdmin = false;
  canAddUser = false;
  nfdcForm: FormGroup;
  enableNfdc: FormControl;
  disableSave: boolean;
  lastUpdate: Number;
  disableNfdc = false;

  constructor(private userService: UserService, private airportService: AirportService) { }

  ngOnInit() {
    this.isPortalAdmin = this.userService.hasRole(['PortalAdmin']);

    if (this.isPortalAdmin) {
      this.cols.push({ field: 'isActive', header: 'Active', width: '6%' })
    }

    this.canAddUser = this.userService.hasRole(['PortalAdmin', 'ServiceCenterAdmin', 'ExternalSubmissionsAdmin']);

    this.user = this.userService.getCurrentUser();
    var center;
    if (this.userService.hasRole(['ServiceCenterManager', 'ServiceCenterAdmin'])) {
      center = this.serviceCenters.find(x => x.id == this.user.serviceCenterId).code;
    }
    this.userService.listUsers(null, null).subscribe(
      (r) => {
        this.users = r;
        // if (this.userService.hasRole(['ExternalSubmissionsAdmin', 'ExternalSubmissionsManager'])) {
        //   this.users = this.users.filter(x => !x.email.endsWith('.gov'));
        // }
        for (var i = 0; i < this.users.length; i++) {
          var u = this.users[i];
          var roles = u.roles;
          var val = [];
          for (var j = 0; j < roles.length; j++) {
            var x = this.rolesDisplay.find(x => x.type == roles[j]);
            if (x) val.push(x.str);
          };
          u['rolesStr'] = val.join(', ');
          if (u.serviceCenterId) {
            u['serviceCenter'] = this.serviceCenters.find(x => x.id == u.serviceCenterId)['str'];
          }

          if (u.airportId) {
            this.airportService.getAirport(u.airportId).subscribe(
              (r) => {
                u['airport'] = r['aptCode'];
              });
          }
        }
      }
    )

    this.enableNfdc = new FormControl('', Validators.required);
    this.nfdcForm = new FormGroup({
      enableNfdc: this.enableNfdc
    })
    this.disableSave = true;

    this.airportService.getNfdcFlag().subscribe(
      (r) => {
        this.enableNfdc.setValue(r['autoUpdate'] ? 'enable' : 'disable');
        this.lastUpdate = r['lastUpdated'];

        this.nfdcForm.valueChanges.subscribe(
          (r) => {
            if (r.enableNfdc) {
              this.disableSave = false;
            }
          }
        )
      }
    )
  }

  updateRowCount(event) {
    this.rowCount = event.value.val;
  }

  onSubmit() {
    var enable = this.enableNfdc.value == 'enable' ? true : false;
    this.airportService.setNfdcFlag(enable).subscribe();
    this.disableSave = true;
  }

  runNfdc() {
    this.disableNfdc = true;
    this.airportService.runNfdcUpdate().subscribe(
      (r) => {
        console.log(r);
      }
    );
  }

  runNfdcNavaids() {
    this.disableNfdc = true;
    this.airportService.runNfdcNavaidsUpdate().subscribe(
      (r) => {
        console.log(r);
      }
    );
  }

  runNfdcAirways() {
    this.disableNfdc = true;
    this.airportService.runNfdcAirwaysUpdate().subscribe(
      (r) => {
        console.log(r);
      }
    );
  }

  evalSubmission() {
    // window.open('http://localhost:4200/editsubmission/30')    
  }
}