// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
    font-size: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.field-label {
    margin-right: 5px;
}

.field-label-table {
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.section-line {
    border-bottom: 1px solid #D5D5D5;
    margin-bottom: 7px;
}

.indent-1 {
    margin-left: 20px;
}

.section-label {
    font-size: 1.2em;
    margin-top: 10px;
}

.field-td {
    text-align: left;
    height: 25px;
}

.dl-link {
    color: #337ab7;
}

.field-pre {
    display: block;
    white-space: pre-wrap;
    margin-top: 0px;
    margin-bottom: 8px;
}

.map-div {
    height: 600px;
    width: 680px;
}

.grid-align-left {
    text-align: left;
}

.form-btn {
    margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/submission-view/submission-view.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".link {\r\n    font-size: 30px;\r\n    display: inline-block;\r\n    margin-top: 15px;\r\n    margin-bottom: 10px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.field-label {\r\n    margin-right: 5px;\r\n}\r\n\r\n.field-label-table {\r\n    margin-right: 5px;\r\n    margin-top: 0px;\r\n    margin-bottom: 0px;\r\n}\r\n\r\n.section-line {\r\n    border-bottom: 1px solid #D5D5D5;\r\n    margin-bottom: 7px;\r\n}\r\n\r\n.indent-1 {\r\n    margin-left: 20px;\r\n}\r\n\r\n.section-label {\r\n    font-size: 1.2em;\r\n    margin-top: 10px;\r\n}\r\n\r\n.field-td {\r\n    text-align: left;\r\n    height: 25px;\r\n}\r\n\r\n.dl-link {\r\n    color: #337ab7;\r\n}\r\n\r\n.field-pre {\r\n    display: block;\r\n    white-space: pre-wrap;\r\n    margin-top: 0px;\r\n    margin-bottom: 8px;\r\n}\r\n\r\n.map-div {\r\n    height: 600px;\r\n    width: 680px;\r\n}\r\n\r\n.grid-align-left {\r\n    text-align: left;\r\n}\r\n\r\n.form-btn {\r\n    margin-bottom: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
