import { Injectable } from '@angular/core';  
  
@Injectable({
  providedIn: 'root',
})

export class OrderService {  
  
private data = {rowCount:0, rowFlag:0};

 constructor() {}
  
 setOption(option, value) {      
   this.data[option] = value;  
 }
  
  getOption() {  
    return this.data;  
  }  
}  