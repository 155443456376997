// The file contents for the current environment will overwrite these during build.
// The build airport defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  accReqUser: {
    email: 'accountRequest@gmail.com',
    password: 'b7b_vJ+>#D'
  },
  production: true,
  envName: "dev",
  apiUrl: ""
  // apiUrl: 'http://localhost:8866'
};
