import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, CheckboxControlValueAccessor, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../shared/service/submission.service';
import { UserService } from '../shared/service/user.service';
import { AirportService } from 'app/shared/service/airport.service';
import { CatexService } from 'app/shared/service/catex.service';
import { CoordinateService } from 'app/shared/service/coordinate.service';
import { ConfirmationService } from 'primeng/api';
import { Submission } from '../shared/class/submission';
import { User } from '../shared/class/user';
import { Airport } from '../shared/class/airport';
import { Navaid } from '../shared/class/navaid';
import { Airway } from '../shared/class/airway';
import { Map, TileLayer, LatLng, GeoJSON, Icon, Marker, Polyline, FeatureGroup } from 'leaflet';
import * as JSZip from 'jszip';
import { $$iterator } from 'rxjs/internal/symbol/iterator';
import { skipUntil } from 'rxjs-compat/operator/skipUntil';
declare var omnivore: any;

@Component({
  selector: 'app-submission-manage',
  templateUrl: './submission-manage.component.html',
  styleUrls: ['./submission-manage.component.css']
})
export class SubmissionManageComponent implements OnInit {
  //#region define variables

  // elements
  @ViewChild('mapDiv') mapDiv: ElementRef;
  @ViewChild('aptCombo') aptCombo: ElementRef;

  // misc
  placeholderAirport = '0AK7';
  isEdit = false;
  showError = false;
  errorMessage: string;
  showAirportForm = false;
  showEnvironmentForm = false;
  showPublic = false;
  isPortalAdmin = false;
  isServiceCenterAdmin = false;
  isProcedureDesigner = false;
  isAFS = false;
  isIFPAdmin=false;

  // file upload
  ifpUploadDisabled = false;
  kmlUploadDisabled = false;
  aptUploadDisabled = false;
  envrUploadDisabled = false;

  consensusFile: Array<any> = [];
  graphicFile: Array<any> = [];
  kmlFile: Array<any> = [];
  additionalFile: Array<any> = [];
  canEdit: boolean[];

  fileType: Array<any> = [
    { type: 'consensus', fid: 1 },
    { type: 'graphic', fid: 2 },
    { type: 'kml', fid: 3 },
    { type: 'additional', fid: 4 },
    { type: 'airport', fid: 5 },
    { type: 'envr', fid: 6 }
  ]

  // forms
  baseForm: FormGroup;
  revisionForm: FormGroup;
  ifpForm: FormGroup;
  airportForm: FormGroup;
  enviromentForm: FormGroup;
  submissionForm: FormGroup;

  // basic
  submissionId: number;
  requestId: string;
  submission: Submission;
  user: User;
  procedureDesignerDisplay: string;
  airports: Airport[];
  navaids: Navaid[];
  airways: Airway[];
  useNavaid: FormControl;
  useAirway: Boolean;
  serviceCenters: Array<any> = [
    { str: 'Central', code: 'C', id: 1 },
    { str: 'Eastern', code: 'E', id: 2 },
    { str: 'Western', code: 'W', id: 3 },
    { str: 'AFS', code: 'A', id: 4 }
  ]
  selectedAirport: FormControl;
  selectedAirportReset: Airport;
  selectedNavaid: FormControl;
  selectedNavaidReset: Navaid;
  selectedAirway: FormControl;
  selectedAirwayReset: Airway;
  alternateLocId: FormControl;
  publicationDate: FormControl;
  airportContacts: User[];
  selectedContact: FormControl;
  inviteEmail: FormControl;
  showEmailInvite: boolean = true;
  procedureDetails: Array<any>;
  colsPD: Array<any> = [
    { field: 'procedureName', header: 'Procedure Name', width: '20%', editable: true},
    { field: 'procedureDescription', header: 'Procedure Description', width: '80%', editable: true }
  ];
  deleted=[{id: '', name: '', description: ''}];
  moreProcedures = false;
  label = 'Additional Procedure Details';
  procedureTable: FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;

  form = this.fb.group({
    procedureName: ['', [Validators.required]],
    procedureDescription: ['', [Validators.required]],
    hidden: ['', [Validators.required]]
  });

  // revision form
  workflowState: FormControl;
  prevWorkflowState: FormControl;
  revisionMessage: FormControl;
  revisionLogs: Array<any>;
  cols: Array<any> = [
    { field: 'submittedTime', header: 'Submitted', width: '20%', isDate: true },
    { field: 'comment', header: 'Revision Log Messages', width: '80%' }
  ];
  showLog = false;

  // ifp specialist
  procedureRequestType: FormControl;
  isSingle: FormControl;
  procedureName: FormControl;
  procedureDescription: FormControl;
  procedureBenefit: FormControl;
  procedureNeed: FormControl;
  procedureTypeIsApproach: FormControl;
  procedureTypeIsDeparture: FormControl;
  procedureTypeIsEnroute: FormControl;
  isOnlyHelicopterOps: FormControl;
  isEmergencyHelo: FormControl;
  showEmergencyHelo = false;
  altitudes: Array<any> = [
    {
      label: 'Surface to 3,000 feet AGL'
    }, {
      label: '3,001 to 7,000 feet AGL'
    }, {
      label: '7,001 to 10,000 feet AGL'
    }, {
      label: '10,001 to 18,000 feet AGL'
    }, {
      label: 'Above 18,000 feet AGL'
    }
  ];
  procedureAltitude: FormControl;
  trackCoordinateInput: FormControl;
  map: Map;
  kmlLayer: GeoJSON;
  oldKMLLayer1: GeoJSON;
  oldKMLLayer2: GeoJSON;
  coordLayer: FeatureGroup;
  useLayer1 = false;
  useLayer2 = false;

  // change details
  isAmended: boolean = false;
  isShowDetails: boolean = true;
  newOrRevised: FormArray;
  newOrRevisedOptions: Array<any> = [
    { label: 'Change to and/or additional Lines of Minimum', val: 'isChangeLinesOfMinimum' },
    { label: 'Altitude increases', val: 'isAltitudeIncreases' },
    { label: 'IFR Takeoff Minimums and (Obstacle) Departure Procedure (ONLY applies to close-in obstacle note and/or no track changes)', val: 'isIfrTakeoffAndDPs' },
    { label: 'Minimum Safe Altitudes', val: 'isMinimumSafeAltitude' },
    { label: 'Changes to circling areas', val: 'isChangeToCircling' },
    { label: 'Arrival holding patterns, not including Hold in Lieu of a Procedure Turn', val: 'isArrivalHolding' },
    { label: 'Visual Climb Over Airport (VCOA) without a route', val: 'isVisualClimbOverAirport' }
  ];
  emergencyActions: FormControl;
  pubActions: FormArray;
  pubActionsOptions: Array<any> = [
    { label: 'Name changes (Airport, Fix, Procedure, etc.)', val: 'isNameChanges' },
    { label: 'Adding, amending, removing notes to procedures', val: 'isAmendingNotes' },
    { label: 'Magnetic Variation (MagVar) adjustments', val: 'isMagneticVariationAdjustments' },
    { label: 'Coding changes with no track/altitude changes', val: 'isCodingChanges' },
    { label: 'Cancellation of IFPs not currently flown', val: 'isCancellationIFPs' }
  ]
  isFieldOnlyChange: FormControl;

  // airport contact
  isAptInCal: boolean = false;
  noiseAreas: string[] = [
    'Residential',
    'Educational',
    'Hospital',
    'Religious Structure or Sites',
    'Recreational',
    'Cultural Sites',
    'Park (Not National)',
    'Wilderness Area (Not National)',
    'Wildlife Refuge (Not National)',
    'National Park',
    'National Wilderness Area',
    'National Wildlife Refuge',
    'Unknown',
    'None'
  ];
  operationCounts: FormArray;
  areas: FormArray;
  airportNotes: FormControl;
  airportFile: Array<any> = [];

  // community engagement specialist
  reviewDate: FormControl;
  reviewerName: FormControl;
  isReviewed: FormControl;

  isPrivate: FormControl;

  // environmental specialist
  selectedServiceCenter: FormControl;
  selectedEnvrsSpecialist: FormControl;
  envrSpecialists: User[];
  selectedHistoricProp: FormControl;
  historicProperties: Array<any> = [
    {
      label: 'None'
    }, {
      label: 'Waiting SHPO Response'
    }, {
      label: 'Historic Properties Impacted'
    }, {
      label: 'No Historic Properties Impacted'
    }, {
      label: 'Letter Has Not Been Sent'
    }
  ];
  shpoDate: FormControl;
  procedureDescriptionCatex: FormControl;
  envrNotes: FormControl;
  filterRecommendation: FormControl;
  isEditable = false;
  showCatexEligible = false;
  showCatexCategory = false;
  showCatexCategoryAirway = false;
  catexEligible: FormControl;
  catexCodes: string[] = [
    'CATEX 5-6.5.h',
    'CATEX 5-6.5.i',
    'CATEX 5-6.5.j',
    'CATEX 5-6.5.k',
    'CATEX 5-6.5.p'
  ];
  catexCodesAirway: string[] = [
    'CATEX 5-6.5.h',
    'CATEX 5-6.5.i',
    'CATEX 5-6.5.j',
    'CATEX 5-6.5.k',
    'CATEX 5-6.5.p',
    'CATEX 5-6.5.b'
  ];
  catex: FormArray;
  envrFile: Array<any> = [];

  procedureInfo: Array<any>;
  colsAP: Array<any> = [
    { field: 'procedureName', header: 'Procedure Name', width: '20%' },
    { field: 'procedureDescription', header: 'Procedure Description', width: '80%' },
  ];
  onlyProcedure=false;

  //#endregion define variables

  //#region lifecycle
  constructor(private subService: SubmissionService,
    private userService: UserService,
    private airportService: AirportService,
    private router: Router,
    private route: ActivatedRoute,
    private catexService: CatexService,
    private confirmationService: ConfirmationService,
    private coordinateService: CoordinateService,
    private fb: FormBuilder) { }

  ngOnInit() {
    // init data

    // get current user info
    this.user = this.userService.getCurrentUser();
    this.userService.listUsers('AirportContact').subscribe(
      (r) => {
        if (r) {
          this.airportContacts = r;
        }
        // else {
        //   this.showEmailInvite = true;
        // }

        this.initSubmission();
      });
      
    // if AFS - show public/private option and set user as AFS
    if (this.user.serviceCenterId==4 || this.user.serviceCenterId==5 || this.userService.hasRole(['ServiceCenterAdmin', 'PortalAdmin'])){
      this.showPublic = true;
      this.isAFS = true;
    }

    this.touchedRows = [];
    this.procedureTable = this.fb.group({
      procedureRows: this.fb.array([])
    });
    //this.addRow();
  }

  initSubmission() {
    this.route.params.subscribe(
      (r) => {
        // if editing existing form
        if (r.id) {
          this.isEdit = true;
          this.subService.getSubmission(r.id).subscribe(
            (r) => {
              if (!r) {
                this.router.navigate(['/submissions']);
              } else {
                var data = r;
                if (data.airportCode=="Airway"){
                  this.useAirway = true;
                }
                if (data.AirportContact!=undefined) {
                  data.airportContactEmail=data.AirportContact.email;
                }
                if (data.ProcedureDesigner!=undefined) {
                  data.ProcedureDesignerEmail=data.ProcedureDesigner.email;
                }
                if (data.EnvironmentalSpecialist!=undefined) {
                  data.EnvironmentalSpecialistEmail=data.EnvironmentalSpecialist.email;
                }
                this.submission = data;
                this.userService.canEdit([this.submission]);

                if (!this.submission['canEdit']) {
                  this.router.navigate(['/submissions']);
                } else {
                  var uploaded = data['SubmissionFiles'];
                  this.consensusFile = uploaded.filter(x => x.FileType.fileTypeId == 1);
                  this.graphicFile = uploaded.filter(x => x.FileType.fileTypeId == 2);
                  this.kmlFile = uploaded.filter(x => x.FileType.fileTypeId == 3);
                  this.additionalFile = uploaded.filter(x => x.FileType.fileTypeId == 4);
                  this.airportFile = uploaded.filter(x => x.FileType.fileTypeId == 5);
                  this.envrFile = uploaded.filter(x => x.FileType.fileTypeId == 6);

                  this.revisionLogs = data['SubmissionComments'].filter(x => x.isRevisionLog!);
                  if (this.revisionLogs.length > 0) {
                    this.showLog = true;
                  }
      
                  this.submissionId = this.submission.submissionId;
                  this.requestId = this.submission.requestId;
                  this.initForm();
                }
              }
            }
          )
        } else {
          var placeholderParams = {
            "airportCode": this.placeholderAirport,
            "procedureDesignerId": this.user.userId,
            "publicationDate": 0,
            "procedureName": "placeholder",
            "procedureDescription": "placeholder",
            "procedureBenefit": "placeholder",
            "procedureNeed": "placeholder"
          }

          var placeholderSub = new Submission(placeholderParams);
          this.subService.createSubmission(placeholderSub).subscribe(
            (r) => {
              this.submissionId = r['data'].items.submissionId;
              this.requestId = r['data'].items.requestId;
            }
          )
          this.initForm();
        }
      });

      this.control = this.procedureTable.get('procedureRows') as FormArray;
  }

  ngAfterViewChecked() {

    if (this.mapDiv && !this.map) {
      // initialize map    
      this.map = new Map(this.mapDiv.nativeElement, {
        scrollWheelZoom: false
      });
      this.map.setView(new LatLng(39.8283, -98.5795), 4.5);
      this.map.addLayer(new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        { maxZoom: 18 })
      );
      this.map.setMinZoom(3.5);
      this.map.on('focus', function (a) {
        a.target.scrollWheelZoom.enable();
      });
      this.map.on('blur', function (a) {
        a.target.scrollWheelZoom.disable();
      });

      if (this.trackCoordinateInput && this.trackCoordinateInput.value) {
        this.updateTrack(this.trackCoordinateInput.value, false)
      }

      if (this.kmlFile.length > 0) {
        var path = this.kmlFile[0].fileName;
        // var path = "../../assets/files/test.kml"; // for local testing
        this.subService.readFile(path).subscribe(
          (r) => {
            this.replotProcedure(r);
          });
      }
    }
  }
  //#endregion lifecycle

  //#region initialize forms
  initForm() {
    try {
      var val = this.isEdit ? this.submission['ProcedureDesigner'] : null;
      var dispUser = val ? new User(val) : this.user;
      this.procedureDesignerDisplay = dispUser.firstName + ', ' + dispUser.lastName + ' (' + dispUser.email + ')';

      this.initBaseForm();
      this.initRevisionForm();
      this.initIfpForm();

      //this.initProcedureDetails();

      this.submissionForm = new FormGroup({
        baseForm: this.baseForm,
        revisionForm: this.revisionForm,
        ifpForm: this.ifpForm
      });

      if (this.submission!=undefined && this.submission.ProcedureDetails.length>0) {
        this.moreProcedures = true;
        const control =  this.procedureTable.get('procedureRows') as FormArray;
        for (var i = 0; i < this.submission.ProcedureDetails.length; i++) {
          if (this.submission.ProcedureDetails[i].hidden==0) {
              control.push(this.addStoredProcedure(this.submission.ProcedureDetails[i].procedureId,this.submission.ProcedureDetails[i].procedureName,this.submission.ProcedureDetails[i].procedureDescription,this.submission.ProcedureDetails[i].hidden,i));
          }
        }
        
        this.procedureDetails=this.submission.ProcedureDetails;
      }

      // attached listeners
      this.setOnFormValueChange();

      if (this.submission) {
        this.initAirportForm();
        this.initEnviromentForm();
        this.resetForms();
      }
    } catch (err) {
      console.error(err);
    }
  }

  resetForms() {
    var sub = this.submission;

    // reset base form
    this.airportService.getAirport(sub.airportId).subscribe(
      (r) => {
        r.label = r.aptCode;
        this.airports = [r];
        this.selectedAirportReset = this.airports[0];
        this.isAptInCal = this.selectedAirportReset.state == 'CA';
        // this.selectedAirport.setValue(this.airports[0]);
      });

    if (sub.navAid) {
      this.useNavaid.setValue(true);
      this.airportService.getNavaid(sub.navAid).subscribe(
        (r) => {
          this.navaids = [r];
          this.selectedNavaidReset = this.navaids[0];
        });
    }

    if (sub.airway) {
      this.airportService.getAirway(sub.airway).subscribe(
        (r) => {
          r.label = r.id;
          this.airways = [r];
          this.selectedAirwayReset = this.airways[0];
        });
    }

    this.publicationDate.setValue(sub.publicationDate ? new Date(sub.publicationDate) : null);
    if (sub.airportContactId != null) {
      this.selectedContact.setValue(this.airportContacts.find(x => x.userId == sub.airportContactId));
    }

    this.inviteEmail.setValue(sub.airportContactEmail);

    // reset revision form
    this.workflowState.setValue(this.submission.workflowState);

    // reset ifp form
    this.procedureRequestType.setValue(sub.procedureRequestType);
    this.isSingle.setValue(sub.isSingle ? 'yes' : 'no');
    this.isPrivate.setValue(sub.isPrivate ? 'yes' : 'no');
    this.procedureName.setValue(sub.procedureName);
    this.procedureDescription.setValue(sub.procedureDescription);
    this.procedureBenefit.setValue(sub.procedureBenefit);
    this.procedureNeed.setValue(sub.procedureNeed);
    this.procedureTypeIsApproach.setValue(sub.isApproachProcedure);
    this.procedureTypeIsDeparture.setValue(sub.isDepartureProcedure);
    this.procedureTypeIsEnroute.setValue(sub.isEnrouteProcedure);

    if (sub.isOnlyHelicopterOps != null) {
      this.isOnlyHelicopterOps.setValue(sub.isOnlyHelicopterOps ? 'yes' : 'no');
    }

    if (sub.isEmergencyHeloRoute != null) {
      this.isEmergencyHelo.setValue(sub.isEmergencyHeloRoute ? 'yes' : 'no');
    }

    this.procedureAltitude.setValue(this.altitudes.find(x => x.label == sub.procedureAltitude));

    var val = sub.trackCoordinateInput;
    this.trackCoordinateInput.setValue(val);
    // if (val) {
    //   var coord = this.coordinateService.parseTrackCoordinate(val);
    //   this.kmlUploadDisabled = (coord && coord.length > 1) ? true : false;
    // }

    // amended
    for (var i = 0; i < this.newOrRevisedOptions.length; i++) {
      var x = this.newOrRevisedOptions[i];
      if (this.submission[x.val]) {
        this.newOrRevised.controls[i].setValue(true);
      } else {
        this.newOrRevised.controls[i].setValue(false);
      }
    }

    this.emergencyActions.setValue(sub.isMissedApproaches);
    for (var i = 0; i < this.pubActionsOptions.length; i++) {
      var x = this.pubActionsOptions[i];
      if (this.submission[x.val]) {
        this.pubActions.controls[i].setValue(true);
      }
    }
    this.isFieldOnlyChange.setValue(sub.isOnlyChangesToProcedure ? 'yes' : 'no');

    // reset airport form
    if (this.airportForm) {
      var counts = this.airportForm.controls.operationCounts['controls'];
      counts[0].setValue(sub.numHelicopterDayOps);
      counts[1].setValue(sub.numHelicopterEveningOps);
      counts[2].setValue(sub.numHelicopterNightOps);
      counts[3].setValue(sub.numPropsDayOps);
      counts[4].setValue(sub.numPropsEveningOps);
      counts[5].setValue(sub.numPropsNightOps);
      counts[6].setValue(sub.numJetsDayOps);
      counts[7].setValue(sub.numJetsEveningOps);
      counts[8].setValue(sub.numJetsNightOps);

      var areas = this.airportForm.controls.areas['controls'];
      areas[0].setValue(sub.isResidential);
      areas[1].setValue(sub.isEducational);
      areas[2].setValue(sub.isHospital);
      areas[3].setValue(sub.isReligiousStructure);
      areas[4].setValue(sub.isRecreational);
      areas[5].setValue(sub.isCulturalSites);
      areas[6].setValue(sub.isParkNotNational);
      areas[7].setValue(sub.isWildernessNotNational);
      areas[8].setValue(sub.isWildlifeNotNational);
      areas[9].setValue(sub.isNationalPark);
      areas[10].setValue(sub.isNationalWilderness);
      areas[11].setValue(sub.isNationalWildlifeRefuge);
      areas[12].setValue(sub.isUnknown);
      areas[13].setValue(sub.isNone);

      // auto-select none if no noise sensitive area is selected
      if (!areas.some(s => s.value)) {
        areas[13].setValue(true);
      }

      this.airportForm.controls.airportNotes.setValue(sub.airportContactNotes);
    }

    // reset envr form    
    if (this.enviromentForm) {
      if (sub.serviceCenterId) {
        var sc = this.serviceCenters.find(x => x.id == sub.serviceCenterId);
        this.selectedServiceCenter.setValue(sc['str']);
        this.userService.listUsers('EnvironmentalSpecialist', sc['code']).subscribe(
          (r) => {
            this.newOrRevised.value.filter(x => x == true).length==0
            // filter to only active users
            let filteredr = [];
                for (let i= 0; i<r.length; i++) {
                    if (r[i].isActive.toString()=="Yes") {
                      filteredr = [...filteredr, r[i]];
                    }
            }
            this.envrSpecialists = filteredr;
            if (sub.environmentalSpecialistId != null) {
              this.selectedEnvrsSpecialist.setValue(this.envrSpecialists.find(x => x.userId == sub.environmentalSpecialistId));
            }
          });
      }

      this.selectedHistoricProp.setValue(
        this.historicProperties.find(x => x.label == sub.historicProperties)
      );

      this.shpoDate.setValue(sub.shpoLetterDateSent ? new Date(sub.shpoLetterDateSent) : null);
      this.procedureDescriptionCatex.setValue(sub.procedureDescriptionForCATEX);
      this.envrNotes.setValue(sub.environmentalSpecialistNotes);
      this.catexEligible.setValue(sub.isCatexEligible == null ? 'na' : (sub.isCatexEligible ? 'yes' : 'no'));

      if (sub.isCatexEligible && sub.airportCode!="Airway") {
        var code = ['h', 'i', 'j', 'k', 'p'];
        for (var i = 0; i < code.length; i++) {
          this.catex.controls[i].setValue(sub['IsCATEX_' + code[i]]);
        }
      } else if (sub.isCatexEligible) {
        var code = ['h', 'i', 'j', 'k', 'p', 'b'];
        for (var i = 0; i < code.length; i++) {
          this.catex.controls[i].setValue(sub['IsCATEX_' + code[i]]);
        }
      }


      this.isReviewed.setValue(sub.isReviewed == null ? 'na' : (sub.isReviewed ? 'yes' : 'no'));
      this.reviewDate.setValue(sub.reviewDate ? new Date(sub.reviewDate) : null);
      this.reviewerName.setValue(sub.reviewerName);
    }

    // reset states
    // if state is not IFP - disable base and IFP 
    if (sub.workflowState != 'IFP' && sub.workflowState != 'Mgr Signature') {
      // this.baseForm.disable();
      this.disableBaseForm();
      this.ifpForm.disable();
      this.ifpUploadDisabled = true;
    }

    // if state is pending - treat it like IFP
    if (sub.workflowState == 'Mgr Signature') {
      this.baseForm.enable();
      this.showAirportForm = false;
      this.aptUploadDisabled = true;

      this.showEnvironmentForm = false;
      
      this.ifpForm.enable();
      this.ifpUploadDisabled = false;
    }   
    
    // if state is Airport - show airport, show but disable envr form (show CATEX recommendation but cannot edit)
    if (sub.workflowState == 'Airport') {
      this.showAirportForm = true;
      this.showEnvironmentForm = true;
      this.enviromentForm.disable();
    } else if (this.airportForm) {
      // if state is not Airport - disable airport form
      this.airportForm.disable();
      this.aptUploadDisabled = true;
    }

    // if state is SC - show airport and envr form, also show catex eligible options
    if (sub.workflowState == 'Service Center') {
      this.showAirportForm = true;
      this.showEnvironmentForm = true;
      this.showCatexEligible = true;
    }

    // if state is closed - show all but disabled (IFP and airport already disabled above)
    if (sub.workflowState == 'Closed') {
      this.showAirportForm = true;
      this.showEnvironmentForm = true;
      this.envrUploadDisabled = true;
      this.enviromentForm.disable();
    }
    
    // if portal admin - show/enable all
    if (this.userService.hasRole(['ProcedureDesigner'])) {
      this.isProcedureDesigner = true;
    }

    // if portal admin - show/enable all
    if (this.userService.hasRole(['PortalAdmin'])) {
      this.isPortalAdmin = true;

      this.baseForm.enable();
      if (this.inviteEmail.value != null) {
        this.inviteEmail.disable();
      }
      this.ifpForm.enable();
      this.ifpUploadDisabled = false;
      if (this.airportForm) {
        this.showAirportForm = true;
        this.airportForm.enable();
        this.aptUploadDisabled = false;
      }
      if (this.enviromentForm) {
        this.showEnvironmentForm = true;
        this.enviromentForm.enable();
        this.showCatexEligible = true;
        this.envrUploadDisabled = false;
      }
    }

    this.isServiceCenterAdmin = this.userService.hasRole(['ServiceCenterAdmin']);
    this.isIFPAdmin = this.userService.hasRole(['IFPAdmin']);

    // evaluate CATEX
    if (this.filterRecommendation) {
      var result = this.catexService.evaluateSubmission(this.submission);
      if (result[0] == 'CATEX') {
        this.filterRecommendation.setValue(result[0] + ' (' + result[1] + ') ' + result[2]);
      } else {
        this.filterRecommendation.setValue(result[0] + '. ' + result[1]);
      }
    }

    // if (!this.catexCodes.includes("CATEX 5-6.5b") && this.submission.airportCode == 'Airway'){
    //   this.catexCodes.push("CATEX 5-6.5b")
    //  }
  }

  initBaseForm() {
    this.selectedAirport = new FormControl('', Validators.required);
    this.useNavaid = new FormControl(false);
    this.selectedNavaid = new FormControl();
    this.selectedAirway = new FormControl();
    this.alternateLocId = new FormControl();
    this.publicationDate = new FormControl();
    this.selectedContact = new FormControl();
    this.inviteEmail = new FormControl();

    this.baseForm = new FormGroup({
      selectedAirport: this.selectedAirport,
      useNavaid: this.useNavaid,
      selectedNavaid: this.selectedNavaid,
      selectedAirway: this.selectedAirway,
      alternateLocId: this.alternateLocId,
      publicationDate: this.publicationDate,
      selectedContact: this.selectedContact,
      inviteEmail: this.inviteEmail
    });
  }

  disableBaseForm() {
    this.selectedAirport.disable();
    this.selectedNavaid.disable();
    this.selectedAirway.disable();
    this.selectedContact.disable();
  }

  initRevisionForm() {
    this.workflowState = new FormControl('IFP');
    this.revisionMessage = new FormControl();

    this.revisionForm = new FormGroup({
      workflowState: this.workflowState,
      revisionMessage: this.revisionMessage
    });
  }

  initIfpForm() {
    this.procedureRequestType = new FormControl('New');
    this.isSingle = new FormControl('yes');
    this.isPrivate = new FormControl('no');
    this.procedureName = new FormControl('', Validators.required);
    this.procedureDescription = new FormControl('', Validators.required);
    this.procedureBenefit = new FormControl('', this.procedureBenefitValidator.bind(this));
    this.procedureNeed = new FormControl('', this.procedureNeedValidator.bind(this));
    this.procedureTypeIsApproach = new FormControl(false);
    this.procedureTypeIsDeparture = new FormControl(false);
    this.procedureTypeIsEnroute = new FormControl(false);
    this.isOnlyHelicopterOps = new FormControl();
    this.isEmergencyHelo = new FormControl();
    this.procedureAltitude = new FormControl('');
    this.trackCoordinateInput = new FormControl('', this.trackCoordinateValidator.bind(this));

    if (this.kmlFile.length > 2) {
      this.kmlUploadDisabled = true;
      // this.trackCoordinateInput.disable();
    }

    this.newOrRevised = new FormArray(
      this.newOrRevisedOptions.map(x => new FormControl(''))
    ); 

    this.emergencyActions = new FormControl(false, Validators.required);
    this.pubActions = new FormArray(
      this.pubActionsOptions.map(x => new FormControl(false), Validators.required)
    )
    this.isFieldOnlyChange = new FormControl('yes');

    this.ifpForm = new FormGroup({
      procedureRequestType: this.procedureRequestType,
      isSingle: this.isSingle,
      isPrivate: this.isPrivate,
      procedureName: this.procedureName,
      procedureDescription: this.procedureDescription,
      procedureBenefit: this.procedureBenefit,
      procedureNeed: this.procedureNeed,
      procedureTypeIsApproach: this.procedureTypeIsApproach,
      procedureTypeIsDeparture: this.procedureTypeIsDeparture,
      procedureTypeIsEnroute: this.procedureTypeIsEnroute,
      isOnlyHelicopterOps: this.isOnlyHelicopterOps,
      isEmergencyHelo: this.isEmergencyHelo,
      procedureAltitude: this.procedureAltitude,
      trackCoordinateInput: this.trackCoordinateInput,
      newOrRevised: this.newOrRevised,
      emergencyActions: this.emergencyActions,
      pubActions: this.pubActions,
      isFieldOnlyChange: this.isFieldOnlyChange
    })
  }

  initAirportForm() {
    var sub = this.submission;
    if (sub.workflowState == 'Airport' || sub.workflowState == 'Service Center' || sub.workflowState == 'Closed') {

      var x = Array.from({ length: 9 }, (x, i) => i);
      this.operationCounts = new FormArray(
        x.map(x => new FormControl(0, [Validators.required, Validators.min(0)]))
      );

      this.areas = new FormArray(
        this.noiseAreas.map(x => new FormControl(false)),
        this.areasValidator
      );
      this.airportNotes = new FormControl();

      this.airportForm = new FormGroup({
        operationCounts: this.operationCounts,
        areas: this.areas,
        airportNotes: this.airportNotes
      });

      this.submissionForm.addControl('airportForm', this.airportForm);
    }
  }

  initEnviromentForm() {
    var sub = this.submission;
    if (sub.workflowState != 'IFP' && sub.workflowState != 'Mgr Signature') {
      this.selectedServiceCenter = new FormControl();
      this.selectedEnvrsSpecialist = new FormControl();
      this.selectedHistoricProp = new FormControl();
      this.shpoDate = new FormControl();
      this.procedureDescriptionCatex = new FormControl();
      this.envrNotes = new FormControl();
      this.filterRecommendation = new FormControl();
      this.catexEligible = new FormControl('na');
      this.isReviewed = new FormControl('na');
      this.reviewDate = new FormControl();
      this.reviewerName = new FormControl();
      if (sub.airportCode=="Airway") {
        this.catex = new FormArray(
          this.catexCodesAirway.map(x => new FormControl())
        )
      } else {
        this.catex = new FormArray(
          this.catexCodes.map(x => new FormControl())
        )
      }
      ;


      if (sub.workflowState == 'Service Center') {
        // this.selectedHistoricProp.setValidators([Validators.required]);
        // this.procedureDescriptionCatex.setValidators([Validators.required]);
        this.isEditable = true;
      }

      this.enviromentForm = new FormGroup({
        selectedServiceCenter: this.selectedServiceCenter,
        selectedEnvrsSpecialist: this.selectedEnvrsSpecialist,
        selectedHistoricProp: this.selectedHistoricProp,
        shpoDate: this.shpoDate,
        procedureDescriptionCatex: this.procedureDescriptionCatex,
        envrNotes: this.envrNotes,
        filterRecommendation: this.filterRecommendation,
        catexEligible: this.catexEligible,
        catex: this.catex,
        isReviewed: this.isReviewed,
        reviewerName: this.reviewerName,
        reviewDate: this.reviewDate,
      });

      this.submissionForm.addControl('enviromentForm', this.enviromentForm);
    }
  }

  // form listeners
  setOnFormValueChange() {

    this.submissionForm.valueChanges.subscribe(
      (r) => {
        if (r.baseForm && r.ifpForm) {
          // toggle email invite form          
          if (!this.selectedContact.disabled && r.baseForm.selectedContact == null && !this.showEmailInvite) {
            this.showEmailInvite = true;
            this.inviteEmail.reset();
          }

          if (r.baseForm.selectedContact != null && this.showEmailInvite) {
            this.showEmailInvite = false;
          }

          this.isAmended = r.ifpForm.procedureRequestType == 'Amended';          
          this.isShowDetails = this.isAmended && r.ifpForm.isFieldOnlyChange == 'yes' ? false : true;
          this.showEmergencyHelo = r.ifpForm.isOnlyHelicopterOps == 'yes';

         // only display the Add Additional Procedures button if the submission is Multiple
          if (this.submission!=undefined) {
            this.onlyProcedure = r.ifpForm.isSingle == 'no' && this.submission.ProcedureDetails.length==0;
          } else {
            this.onlyProcedure = r.ifpForm.isSingle == 'no';
          }
        }

        if (r.enviromentForm) {
          this.showCatexCategory=r.enviromentForm.catexEligible == 'yes';
          if (r.baseForm.selectedAirport!='' && r.baseForm.selectedAirport!=undefined){
            this.showCatexCategory = (r.baseForm.selectedAirport.aptCode != 'Airway' && r.enviromentForm.catexEligible == 'yes');
            this.showCatexCategoryAirway = (r.baseForm.selectedAirport.aptCode == 'Airway' && r.enviromentForm.catexEligible == 'yes');
          }
        }
        })

    // update track on map
    this.trackCoordinateInput.valueChanges.subscribe(
      (r) => {
        this.updateTrack(r, true)
      }
    )
  }
  //#endregion initialize forms

  //#region methods
  updateAirportList(event, cmp) {
    if (event.originalEvent.type == 'input') {
      this.airportService.getAirports(cmp.value).subscribe(
        (r) => {
          for (var i = 0; i < r.length; i++) {
            var aptCode = r[i].aptCode;
            if (r[i].aptCode.startsWith('---')) {
              r[i].label = r[i].facilityName;
            } else {
              r[i].label = aptCode;
            }
            if (aptCode=="Airway"){
              this.useAirway = true;
            } else {
              this.useAirway = false;
            }
          }
          this.airports = r;
          cmp.show();
        });
    }
  }

  updateNavaidList(event, cmp) {
    if (event.originalEvent.type == 'input') {
      this.airportService.getNavaids(cmp.value).subscribe(
        (r) => {
          this.navaids = r;
          cmp.show();
        });
    }
  }

  updateAirwayList(event, cmp) {
    if (event.originalEvent.type == 'input') {
      this.airportService.getAirways(cmp.value).subscribe(
        (r) => {
          for (var i = 0; i < r.length; i++){
            r[i].label=r[i].id;
          }
          this.airways = r;
          cmp.show();
        });
    }
  }  

  testFunct(sc) {
    var sub = this.submission;
    this.userService.listUsers('EnvironmentalSpecialist', sc).subscribe(
      (r) => {
        this.envrSpecialists = r;
      });
  }
  //#endregion

  //#region validators
  areasValidator(arr: FormArray) {
    if (arr.controls.map(x => x.value).filter(x => x == true).length == 0) {
      return { noAreas: true };
    }
    return null;
  }

  trackCoordinateValidator(ctrl: FormControl) {
    if (ctrl.value == null || ctrl.value.length == 0) {
      return null; // track coord is not required

      // add condition for kml    
      // if (this.kmlFile.length > 0) {
      //   return null;
      // } else {
      //   return { required: true };
      // }
    }

    var coord = this.coordinateService.parseTrackCoordinate(ctrl.value);
    if (coord == null || coord.length == 0) {
      return { invalid: true };
    }
    return null;
  }

  catexValidator(arr: FormArray) {
    if (arr.controls.map(x => x.value).filter(x => x == true).length == 0) {
      return { noCatex: true };
    }
    return null;
  }
  //#endregion validators

  procedureNeedValidator(ctrl: FormControl) {
      if (ctrl.value == "" && this.baseForm.touched) {  
      return { noProcedureNeed: true };
    }
    return null;
  }

  procedureBenefitValidator(ctrl: FormControl) {
      if (ctrl.value == "" && this.baseForm.touched) {  
      return { noProcedureBenefit: true };
    }
    return null;
  }

  //#region errors
  isValid(cmp) {
    if (this[cmp].dirty || this[cmp].touched) {
      return this[cmp].invalid ? 'has-error' : 'has-success';
    }
    return null;
  }

  hasError(cmp) {
    return (this[cmp].errors || this[cmp].invalid) && (this[cmp].dirty || this[cmp].touched);
  }

  hasEntered(cmp) {
    if (cmp == 'selectedAirport') {
      return (this.baseForm.controls.selectedAirport.value == undefined || this.baseForm.controls.selectedAirport.value == "");
    }
    if (cmp == 'procedureName') {
      return (this.ifpForm.controls.procedureName.value == "");
    }
    if (cmp == 'procedureDescription') {
      return (this.ifpForm.controls.procedureDescription.value == "");
    } 
    if (cmp == 'procedureBenefit') {
      return (this.ifpForm.controls.procedureBenefit.value == "" && (this.requestId==undefined || this.requestId.startsWith('placeholder_')));
    } 
    if (cmp == 'procedureNeed') {
      return (this.ifpForm.controls.procedureNeed.value == "" && (this.requestId==undefined || this.requestId.startsWith('placeholder_')))
    } 
    if (cmp == 'areas') {
      if (this.workflowState.value == "IFP" || this.workflowState.value == "Mgr Signature" || this.airportForm ==undefined) {
        return (false);
      } else {
        return (!this.airportForm.controls.areas.value.includes(true));
      }
    }
    if (cmp == 'operationCounts') {
      if (this.workflowState.value == "IFP" || this.workflowState.value == "Mgr Signature" || this.airportForm ==undefined) {
        return false;
      } else {
        for (let i = 0; i < this.airportForm.controls.operationCounts.value.length; i++) {
          if (this.airportForm.controls.operationCounts.value[i] == null) {
            return true;
            break
          }
        }
      }
    }
    if (cmp == 'reviewerName') {
      if (this.workflowState.value == "IFP" || this.workflowState.value == "Mgr Signature" || this.airportForm ==undefined) {
        return false;
      } else {
        if (this.enviromentForm.controls.reviewerName.status == "INVALID") {
          return true;
        }
      }
    }
  }

  getErrorMsg(cmp) {
    var msg: string = '';

    if (cmp == 'selectedAirport') {
      msg = 'Airport is required.';
    } else if (cmp == 'inviteEmail') {
      var errors = this[cmp].errors;
      if (errors.required) {
        msg = 'Email is required.';
      } else if (errors.pattern) {
        msg = 'Invalid email address.';
      }
    } else if (cmp == 'inviteEmailDet') {
      msg = 'Enter a valid e-mail address for the New Airport Contact E-mail.';
    } else if (cmp == 'procedureName') {
      msg = 'Procedure name is required.'
    } else if (cmp == 'procedureDescription' || cmp == 'procedureDescriptionCatex') {
      msg = 'Procedure description is required.'
    } else if (cmp == 'selectedAltitude') {
      msg = 'Altitude is required.';
    } else if (cmp == 'areas') {
      msg = 'Must select at least 1.';
    } else if (cmp == 'noiseAreas') {
      msg = 'Must select at least 1 noise area in the Airport Contact tab.';
    } else if (cmp == 'operationCounts') {
      msg = 'Count is required and must be greater than or equal to 0.';
    } else if (cmp == 'operationCountsDet') {
      msg = 'Annual Operations Count is required and must be greater than or equal to 0.';
    } else if (cmp == 'reviewerName') {
      msg = 'Only alphanumeric characters are allowed.';
    } else if (cmp == 'reviewerNameDet') {
      msg = 'Only alphanumeric characters are allowed in the Community Engagement Specialist Name.';
    } else if (cmp == 'selectedEnvrsSpecialist') {
      msg = 'Environmental specialist is required.';
    } else if (cmp == 'selectedHistoricProp') {
      msg = 'Historic properties selection is required.';
    } else if (cmp == 'procedureNeed') {
      msg = 'Procedure need is required.';
    } else if (cmp == 'procedureBenefit') {
      msg = 'Procedure benefit is required.';
    } else if (cmp == 'trackCoordinateInput') {
      var errors = this[cmp].errors;
      if (errors.invalid) {
        msg = 'Invalid track coordinate values.';
      } else if (errors.required) {
        msg = 'Procedure track is required.';
      } 
    }
    return msg;
  }
  //#endregion errors

  //#region submission files  

  uploadFile(cmp, type) {
    var fs = cmp.files;
    var type = this.fileType.find(x => x.type == type);
    var fid = type ? type.fid : 8;

    this.subService.uploadFile(this.submissionId, fs, fid.toString()).subscribe(
      (r) => {
        if (r['error'].message.name) {
          console.log(r['error']);
          this.errorMessage = 'File upload failed.'
          this.showError = true;
        } else {
          cmp.clear();

          var uploaded = r['data'].items;
          for (var i = 0; i < uploaded.length; i++) {
            var f = uploaded[i];
            var fid = Number(f.fileTypeId);
            var type = this.fileType.filter(x => x.fid == fid).map(x => x.type);
            this[type + 'File'].push({
              fileId: f.fileId,
              originalFileName: f.originalFileName,
              FileType: {
                fileTypeId: fid
              }
            });
            if (fid == 3 && fs[0].name[fs[0].name.length - 1] == "z") {
              this.onKmzUpload(fs[0]);
              if (this.kmlFile.length > 2) {
                this.kmlUploadDisabled = true;
                // this.trackCoordinateInput.disable();
              }
              // this.kmlUploadDisabled = true;
              this.trackCoordinateInput.updateValueAndValidity();
              // this.trackCoordinateInput.disable();
            } else if (fid == 3) {
              this.onKmlUpload(fs[0]);
              if (this.kmlFile.length > 2) {
                this.kmlUploadDisabled = true;
                // this.trackCoordinateInput.disable();
              }
              // this.kmlUploadDisabled = true;
              this.trackCoordinateInput.updateValueAndValidity();
              // this.trackCoordinateInput.disable();
            }
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hasUploaded(type) {
    var fs = this[type + 'File'];
    if (fs) {
      return this[type + 'File'].length > 0;
    }
    return false;
  }

  confirmDelete(fs) {
    this.confirmationService.confirm({
      message: 'Delete ' + fs.originalFileName + ' ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteFile(fs);
      },
      reject: () => {
      }
    });
  }

  deleteFile(fs) {
    var type = this.fileType.find(x => x.fid == fs.FileType.fileTypeId)['type'];

    var fileIndex = 0;
    for (let i = 0; i <= this.kmlFile.length - 1; i++) {
      if (fs.originalFileName == this.kmlFile[i].originalFileName) {
        fileIndex = i;
      }

    }
    this.subService.deleteFile(fs.fileId).subscribe(
      (r) => {
        if (r['message'] == 'Submission file removed successfully') {
          this[type + 'File'] = this[type + 'File'].filter(x => x.fileId != fs.fileId);
          if (type == 'kml') {
            // this.kmlUploadDisabled = false;
            if (this.kmlFile.length > 2) {
              this.kmlUploadDisabled = true;
              // this.trackCoordinateInput.disable();
            } else {
              this.kmlUploadDisabled = false;
            }
            if (this.kmlLayer) {
              // find index of the file in the kml file list to know which layer to delete
              if (fileIndex == 0 && this.useLayer2) {
                this.oldKMLLayer2.remove();
                this.useLayer2 = false;
              } else if (fileIndex == 0 && this.useLayer1) {
                this.oldKMLLayer1.remove();
                this.useLayer1 = false;
              } else if (fileIndex == 0) {
                this.kmlLayer.remove();
              } else if (fileIndex == 1 && this.useLayer1) {
                this.oldKMLLayer1.remove();
                this.useLayer1 = false;
              } else if (fileIndex == 1) {
                this.kmlLayer.remove();
              } else if (fileIndex == 2) {
                this.kmlLayer.remove();
              }

            }
            this.trackCoordinateInput.enable();
            this.trackCoordinateInput.updateValueAndValidity();
          }
        } else {
          this.errorMessage = 'File deletion failed.'
          this.showError = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  //#endregion

  //#region map

  getIcon() {
    var m = 18;
    var icon = new Icon({
      iconUrl: '../../assets/images/circle_grey.png',
      iconSize: [m, m],
      iconAnchor: [m / 2, m / 2]
    });

    return icon;
  }

  initMap() {
    if (this.kmlLayer) {
      // this.kmlLayer.remove();
      if (this.oldKMLLayer1) {
        this.oldKMLLayer2 = this.oldKMLLayer1;
        this.oldKMLLayer1 = this.kmlLayer;
        this.useLayer2 = true;
      } else {
        this.oldKMLLayer1 = this.kmlLayer;
        this.useLayer1 = true;
      }
    }

    var icon = this.getIcon();
    this.kmlLayer = new GeoJSON(null, {
      pointToLayer: function (geoJsonPoint, latlng) {
        return new Marker(latlng, {
          icon: icon
        })
      }
    });
  }

  onKmlUpload(fs) {
    this.initMap();

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      omnivore.kml(fileReader.result, null, this.kmlLayer)
        .on('ready', function (x) {
          var l = x.target;
          l._map.fitBounds(l.getBounds());
        })
        .addTo(this.map);
    }
    fileReader.readAsDataURL(fs);
  }

  onKmzUpload(fs) {
    this.initMap();
    var _this = this;

    // this is needed to get the map to zoom correctly
    var polygonGroup = new FeatureGroup();

    // Add group to map
    this.map.addLayer(polygonGroup);

    JSZip.loadAsync(fs)
      .then(function (zip) {
        return zip.file(Object.keys(zip.files)[0]).async('string');
      })
      .then(function success(kml) {
        var p = omnivore.kml.parse(kml, null, _this.kmlLayer).addTo(polygonGroup);
        p.eachLayer(function (x) {
          if (x._bounds) {
            x._map.fitBounds(polygonGroup.getBounds());
          }
        })
      })
  }

  replotProcedure(str) {
    this.initMap();

    omnivore.kml.parse(str, null, this.kmlLayer).addTo(this.map);
    this.map.fitBounds(this.kmlLayer.getBounds());
  }

  updateTrack(val, isReset) {
    if (this.map) {
      if (this.coordLayer) {
        this.coordLayer.remove();
      }

      var coord = this.coordinateService.parseTrackCoordinate(val);
      var toDisable = false;
      if (coord && coord.length > 1) {
        toDisable = true;
        var markers = [];
        for (var i = 0; i < coord.length; i++) {
          markers.push(new Marker(coord[i], {
            icon: this.getIcon()
          }));
        }

        this.coordLayer = new FeatureGroup(markers)
          .addLayer(new Polyline(coord, { 'color': '#1e5199' }))
          .addTo(this.map);
        this.map.fitBounds(this.coordLayer.getBounds());
      }

      // if (isReset) {
      //   this.kmlUploadDisabled = toDisable;
      // }
    }
  }

  //#endregion map

  //#region submit form
  onSubmit() {
    var sub = new Submission();
    sub.submissionId = this.submissionId;

    // show error if change details are not filled out
    if (this.isAmended && this.isFieldOnlyChange.value=='yes' && this.newOrRevised.value.filter(x => x == true).length==0 && this.emergencyActions.value==false && this.pubActions.value.filter(x => x == true).length==0 && this.baseForm.touched) {
      this.errorMessage = 'A selection is required under Change Details.'
      this.showError = true;
      return false;
    }

    var base = this.submissionForm.controls.baseForm.value;
    sub.procedureDesignerId = this.isEdit ? this.submission.procedureDesignerId : this.user.userId;
    // var apt = base.selectedAirport;
    var apt = this.baseForm.controls.selectedAirport.value
    sub.airportId = apt.airportId;
    sub.airportCode = apt.aptCode;
    if (this.baseForm.controls.useNavaid.value) {
      sub.navAid = this.baseForm.controls.selectedNavaid.value.uniqueId;
    } else {
      sub.navAid = '';
    } 
    if (this.baseForm.controls.selectedAirway.value!=undefined) {
      sub.airway = this.baseForm.controls.selectedAirway.value.id;
    } else {
      sub.airway = '';
    }
    
    // automatically assign airport to AFS if it comes from a 3rd party designer
    // assign to the user's service center if they select airway instead of an airport
    // otherwise assign based on the airport location
    if (this.user.serviceCenterId==4 || this.user.serviceCenterId==5) {
      sub.serviceCenterId = 4;
    } else if (this.airports[0].aptCode=="Airway") {
      sub.serviceCenterId = this.user.serviceCenterId;
    } else {
      sub.serviceCenterId = this.serviceCenters.find(x => x.code == apt.serviceCenter)['id'];
    }

    // if submission is now archived or deleted, save the previous workflow state
    if (this.submissionForm.controls.revisionForm.value.workflowState=='Archive' || this.submissionForm.controls.revisionForm.value.workflowState=='Delete'){
      sub.prevWorkflowState=this.submission.workflowState;
    }

    // var selectedAirport = base.selectedAirport;
    // if (typeof selectedAirport == 'string') {
    //   selectedAirport = this.airports.find(x => x.aptCode == selectedAirport);
    // }
    // sub.airportId = selectedAirport.airportId;
    // sub.airportCode = selectedAirport.aptCode;

    if (this.requestId.startsWith('placeholder_')) {
      var aptCode = sub.airportCode;
      if (aptCode.startsWith('---')) {
        aptCode = '---';
      }
      sub.requestId = this.requestId.replace('placeholder', aptCode);
    }

    // sub.publicationDate = base.publicationDate ? base.publicationDate.getTime() : null;
    var pubDate = this.baseForm.controls.publicationDate.value;
    sub.publicationDate = pubDate ? pubDate.getTime() : null;
    if (base.selectedContact) {
      sub.airportContactId = base.selectedContact.userId;
    }
    if (base.inviteEmail) {
      sub.airportContactEmail = base.inviteEmail;
    }

    var revis = this.submissionForm.controls.revisionForm.value;
    sub.workflowState = revis.workflowState;

    var ifp = this.submissionForm.controls.ifpForm.value;
    sub.procedureRequestType = ifp.procedureRequestType;
    sub.isSingle = ifp.isSingle == 'yes' ? true : false;
    sub.isPrivate = ifp.isPrivate == 'yes' ? true : false;
    sub.procedureName = ifp.procedureName;
    sub.procedureDescription = ifp.procedureDescription;
    sub.procedureBenefit = ifp.procedureBenefit;
    sub.procedureNeed = ifp.procedureNeed;
  
    // procedure type
    sub.isApproachProcedure = ifp.procedureTypeIsApproach;
    sub.isDepartureProcedure = ifp.procedureTypeIsDeparture;
    sub.isEnrouteProcedure = ifp.procedureTypeIsEnroute;

    if (ifp.isOnlyHelicopterOps != null) {
      sub.isOnlyHelicopterOps = ifp.isOnlyHelicopterOps == 'yes' ? true : false;
    }

    if (ifp.isEmergencyHelo != null) {
      sub.isEmergencyHeloRoute = ifp.isEmergencyHelo == 'yes' ? true : false;
    }

    if (ifp.procedureAltitude != null) {
      sub.procedureAltitude = ifp.procedureAltitude.label;
    }

    sub.trackCoordinateInput = ifp.trackCoordinateInput;

    if (sub.procedureRequestType == 'Amended') {
      var nr = ifp.newOrRevised;
      if (nr[0]==undefined || nr[0]=='') {sub.isChangeLinesOfMinimum = false} else {sub.isChangeLinesOfMinimum=nr[0]};
      if (nr[1]==undefined || nr[1]=='') {sub.isAltitudeIncreases = false} else {sub.isAltitudeIncreases=nr[1]};
      if (nr[2]==undefined || nr[2]=='') {sub.isIfrTakeoffAndDPs = false} else {sub.isIfrTakeoffAndDPs=nr[2]};
      if (nr[3]==undefined || nr[3]=='') {sub.isMinimumSafeAltitude = false} else {sub.isMinimumSafeAltitude=nr[3]};
      if (nr[4]==undefined || nr[4]=='') {sub.isChangeToCircling = false} else {sub.isChangeToCircling=nr[4]};
      if (nr[5]==undefined || nr[5]=='') {sub.isArrivalHolding = false} else {sub.isArrivalHolding=nr[5]};
      if (nr[6]==undefined || nr[6]=='') {sub.isVisualClimbOverAirport = false} else {sub.isVisualClimbOverAirport=nr[6]};
      sub.isMissedApproaches = ifp.emergencyActions;

      var pa = ifp.pubActions;
      sub.isNameChanges = pa[0];
      sub.isAmendingNotes = pa[1];
      sub.isMagneticVariationAdjustments = pa[2];
      sub.isCodingChanges = pa[3];
      sub.isCancellationIFPs = pa[4];

      sub.isOnlyChangesToProcedure = ifp.isFieldOnlyChange == 'yes' ? true : false;
    }

    if (this.submissionForm.controls.airportForm) {
      var apt = this.submissionForm.controls.airportForm.value;
      var counts = apt.operationCounts;
      sub.numHelicopterDayOps = counts[0];
      sub.numHelicopterEveningOps = counts[1];
      sub.numHelicopterNightOps = counts[2];
      sub.numPropsDayOps = counts[3];
      sub.numPropsEveningOps = counts[4];
      sub.numPropsNightOps = counts[5];
      sub.numJetsDayOps = counts[6];
      sub.numJetsEveningOps = counts[7];
      sub.numJetsNightOps = counts[8];
      var areas = apt.areas;
      sub.isResidential = areas[0];
      sub.isEducational = areas[1];
      sub.isHospital = areas[2];
      sub.isReligiousStructure = areas[3];
      sub.isRecreational = areas[4];
      sub.isCulturalSites = areas[5];
      sub.isParkNotNational = areas[6];
      sub.isWildernessNotNational = areas[7];
      sub.isWildlifeNotNational = areas[8];
      sub.isNationalPark = areas[9];
      sub.isNationalWilderness = areas[10];
      sub.isNationalWildlifeRefuge = areas[11];
      sub.isUnknown = areas[12];
      sub.isNone = areas[13];

      sub.airportContactNotes = apt.airportNotes;
    }

    if (this.submissionForm.controls.enviromentForm && this.enviromentForm.enabled) {
      var envr = this.submissionForm.controls.enviromentForm.value;
      if (envr.selectedServiceCenter) {
        sub.serviceCenterId = this.serviceCenters.find(x => x.str == envr.selectedServiceCenter)['id']
      }
      sub.environmentalSpecialistId = envr.selectedEnvrsSpecialist ? envr.selectedEnvrsSpecialist.userId : null;
      sub.historicProperties = envr.selectedHistoricProp ? envr.selectedHistoricProp.label : null;
      sub.shpoLetterDateSent = envr.shpoDate;
      sub.procedureDescriptionForCATEX = envr.procedureDescriptionCatex
      sub.environmentalSpecialistNotes = envr.envrNotes;
      sub.FilterToolRecommendation = envr.filterRecommendation;
      sub.isCatexEligible = envr.catexEligible == 'na' ? null : (envr.catexEligible == 'yes' ? true : false);
      if (sub.isCatexEligible) {
        var catex = envr.catex.map(x => x ? x : false);
        sub.IsCATEX_h = catex[0];
        sub.IsCATEX_i = catex[1];
        sub.IsCATEX_j = catex[2];
        sub.IsCATEX_k = catex[3];
        sub.IsCATEX_p = catex[4];
        if (catex.length>5) {
          sub.IsCATEX_b = catex[5];
        }
      } else {
        sub.IsCATEX_h = sub.IsCATEX_i = sub.IsCATEX_j = sub.IsCATEX_k = sub.IsCATEX_p = sub.IsCATEX_b =false;
      }
      sub.isReviewed = envr.isReviewed == 'na' ? null : (envr.isReviewed == 'yes' ? true : false);
      sub.reviewDate = envr.reviewDate;
      sub.reviewerName = envr.reviewerName;
    }

    // save any changes to the procedure details
    if (this.procedureTable.touched) {
      const control = this.procedureTable.get('procedureRows') as FormArray;
      this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    }

    var dets = this.procedureTable.value;

    // mark dirty form when error
    this.subService.updateSubmission(sub).subscribe(
      (r) => {
        if (r['error'].message) {
          console.log(r['error']);
          this.errorMessage = 'Saving submission failed.';
          this.showError = true;
          this.markDirty();
        } else {
          this.markAsPristine();
          // add additional procedure names and descriptions - use the index to find out if something is new
          // if it's existing, update it in the database
          for (var i = 0; i < dets.procedureRows.length; i++) {
            if (dets.procedureRows[i].index===''){
                this.subService.addDetails(sub.submissionId,dets.procedureRows[i].procedureName,dets.procedureRows[i].procedureDescription,dets.procedureRows[i].hidden).subscribe(
                    (x) => {
                      this.router.navigate(['/submissions']);
                    }
                  )
            } else {
                this.subService.updateDetails(dets.procedureRows[i].procedureId,sub.submissionId,dets.procedureRows[i].procedureName,dets.procedureRows[i].procedureDescription,dets.procedureRows[i].hidden).subscribe(
                  (x) => {
                    this.router.navigate(['/submissions']);
                  }
                ) 
            }
          }

          // mark deleted rows as hidden in the database
          for (var i = 1; i < this.deleted.length; i++) {
                this.subService.updateDetails(parseInt(this.deleted[i].id),sub.submissionId,this.deleted[i].name,this.deleted[i].description,1).subscribe(
                    (x) => {
                      this.router.navigate(['/submissions']);
                    }
                  )
            }

          if (revis.revisionMessage) {
            this.subService.addComment(sub.submissionId, revis.revisionMessage, false).subscribe(
              (x) => {
                this.router.navigate(['/submissions']);
              }
            )
          } else {
            this.router.navigate(['/submissions']);
          }
        }
      }
    );
  }

  addProcedure() {
    this.moreProcedures = true;

    this.onlyProcedure=false;

    const control =  this.procedureTable.get('procedureRows') as FormArray;
    control.push(this.initiateForm());
  }

  initiateForm(): FormGroup {
    return this.fb.group({
      procedureName: [''],
      procedureDescription: [''],
      index: [''],
      hidden: [0],
      isEditable: [true]
    });
  }

  addStoredProcedure(pid,name,description,hidden,index): FormGroup {
    return this.fb.group({
      procedureId: [pid],
      procedureName: [name],
      procedureDescription: [description],
      hidden: [hidden],
      index: [index],
      isEditable: [false]
    });
  }

  addRow() {
    const control =  this.procedureTable.get('procedureRows') as FormArray;
    control.push(this.initiateForm());
  }

  deleteRow(index: number) {
    const control =  this.procedureTable.get('procedureRows') as FormArray;
    // change the value to hidden for the procedureTable
    //this.procedureDetails[this.procedureTable.value.procedureRows[index].hidden]=1;
    this.procedureTable.value.procedureRows[index].hidden=1;
    // save this data into a separate array so we can update it in the database
    this.deleted.push({id: this.procedureTable.value.procedureRows[index].procedureId, name: this.procedureTable.value.procedureRows[index].procedureName, description: this.procedureTable.value.procedureRows[index].procedureDescription})
    control.removeAt(index);
  }

  editRow(group: FormGroup) {    
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.procedureTable.value);
  }

  get getFormControls() {
    const control = this.procedureTable.get('procedureRows') as FormArray;
    return control;
  }

  submitForm() {
    const control = this.procedureTable.get('procedureRows') as FormArray;
    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    console.log(this.touchedRows);
  }

  markDirty() {
    for (var x0 in this.submissionForm.controls) {
      var f = this.submissionForm.controls[x0];
      for (var x1 in f['controls']) {
        f['controls'][x1].markAsDirty();
      }
    }
  }

  markAsPristine() {
    for (var x0 in this.submissionForm.controls) {
      var f = this.submissionForm.controls[x0];
      for (var x1 in f['controls']) {
        f['controls'][x1].markAsPristine();
      }
    }
  }

  cancelForm() {
    this.markAsPristine();
    this.router.navigate(['/submissions']);
  }

  canDeactivate() {
    if (this.submissionForm) {
      for (var x0 in this.submissionForm.controls) {
        var f = this.submissionForm.controls[x0];
        for (var x1 in f['controls']) {
          if (f['controls'][x1]['dirty']) {
            this.errorMessage = 'There are unsaved changes. Please save or cancel changes.'
            this.showError = true;
            return false;
          }
        }
      }
    }
    return true;
  }
  //#endregion submit form

  debug() {
    debugger;
    // for (var x0 in this.submissionForm.controls) {
    //   var f = this.submissionForm.controls[x0];
    //   for (var x1 in f['controls']) {
    //     var invalid = f['controls'][x1].invalid;
    //     if (invalid) {
    //       console.log(x1 + ', invalid');
    //     }
    //   }
    // }
  }
 
}
