import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../shared/service/data.service';
import { UserService } from '../shared/service/user.service';
import { News } from '../shared/class/news';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: News;
  btnLabel: string = 'Edit';
  isEditing: boolean = false;
  canEdit: boolean = false;

  @ViewChild('editText') editText: ElementRef;

  constructor(private dataService: DataService, private userService: UserService) { }

  ngOnInit() {
    this.dataService.getNews().subscribe(
      (r) => {
        this.news = r;
      });

    this.canEdit = this.userService.hasRole(['PortalAdmin']);
  }

  toggleEditNews() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.btnLabel = 'Save';
    } else {
      this.btnLabel = 'Edit';
      this.news.value = this.editText.nativeElement.value;
      this.dataService.updateNews(this.news).subscribe();
    }
  }

  cancelEdit() {
    this.isEditing = false;
    this.btnLabel = 'Edit';
  }
}
