// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small-block {
    display: block;
    margin-top: 3px;
    margin-bottom: 5px;
}

.btn-custom {
    color: #0d6efd !important;
    padding-left: 0px;
    /* background-color: aqua; */
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,4BAA4B;AAChC","sourcesContent":[".small-block {\r\n    display: block;\r\n    margin-top: 3px;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.btn-custom {\r\n    color: #0d6efd !important;\r\n    padding-left: 0px;\r\n    /* background-color: aqua; */\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
