import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { User } from '../shared/class/user';
import { UserService } from '../shared/service/user.service';

@Component({
  selector: 'faa-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../templates/4/assets/css/main.css',
    './header.component.css']
})
export class HeaderComponent implements OnInit {
  isCatex: boolean = false;
  isLoggedIn: boolean = false;
  canAddSubmission: boolean = false;
  hasAdminRights: boolean = false;
  adminRoles: string[] = [
    'PortalAdmin',
    'ServiceCenterAdmin',
    'ServiceCenterManager',
    'ExternalSubmissionsAdmin',
    'ExternalSubmissionsManager'
  ];

  constructor(private router: Router, private userService: UserService) { }

  ngOnInit(): void {
    this.router.events.subscribe(
      (r) => {
        if (r instanceof NavigationEnd) {
          this.isCatex = r.url.indexOf('catex') > -1 ? true : false;
        }
      });

    this.userService.loginEvent.subscribe(
      (r) => {
        if (r) this.setVar();
      }
    )

    if (this.userService.isLoggedIn()) this.setVar();
  }

  private setVar() {
    if (this.userService.isReqAccUser()) {
      this.isLoggedIn = false;
      this.hasAdminRights = false;
      this.canAddSubmission = false;
    } else {
      this.isLoggedIn = true;
      this.hasAdminRights = this.userService.hasRole(this.adminRoles);
      this.canAddSubmission = this.userService.hasRole(['ProcedureDesigner', 'PortalAdmin']);
    }
  }

  private logOut() {
    this.isLoggedIn = false;
    this.userService.logOut();
    this.router.navigate(['/login']);
  }
}