import { Component, OnInit } from '@angular/core';

import { DataService } from '../shared/service/data.service';
import { News } from '../shared/class/news';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  // variables  
  news: News;
  btnLabel: string = 'Edit';
  editing: boolean = false;

  constructor(private rest: DataService) { }

  ngOnInit() {
    this.rest.getNews().subscribe(
      (r) => {
        this.news = r;
      });
  }
}
