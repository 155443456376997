// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block {
    display: block;
}

.small-block {
    display: block;
    margin-bottom: 5px;
}

.font90 {
    font-size: 90%
}

.map-div {
    height: 600px;
}

.input-file {
    display: inline;
    padding: 1px;
    border: #ccc solid 1px;
}

.form-btn {
    margin-bottom: 30px;
    margin-top: 20px;
}

.grid-align-left {
    text-align: left;
}

.disabled {
    color: #d6d6d6;
}

.btn1{
    margin-right: 10px;
}

.example-class{
    height:400px
}

.inputField {
    width: 350000px !important;
 }


`, "",{"version":3,"sources":["webpack://./src/app/submission-manage/submission-manage.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,0BAA0B;CAC7B","sourcesContent":[".block {\r\n    display: block;\r\n}\r\n\r\n.small-block {\r\n    display: block;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.font90 {\r\n    font-size: 90%\r\n}\r\n\r\n.map-div {\r\n    height: 600px;\r\n}\r\n\r\n.input-file {\r\n    display: inline;\r\n    padding: 1px;\r\n    border: #ccc solid 1px;\r\n}\r\n\r\n.form-btn {\r\n    margin-bottom: 30px;\r\n    margin-top: 20px;\r\n}\r\n\r\n.grid-align-left {\r\n    text-align: left;\r\n}\r\n\r\n.disabled {\r\n    color: #d6d6d6;\r\n}\r\n\r\n.btn1{\r\n    margin-right: 10px;\r\n}\r\n\r\n.example-class{\r\n    height:400px\r\n}\r\n\r\n.inputField {\r\n    width: 350000px !important;\r\n }\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
