import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../class/user';
import { Submission } from '../class/submission';
import { of } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable()
export class UserService {

  private loggedInUser: User;
  loginEvent = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) { }

  // #region login/logout
  public logIn(user: User): Observable<boolean> {
    localStorage.removeItem('token');
    var params = {
      email: user.email, password: user.password
    };
    var _this = this;
    return this.http
      .post(API_URL + '/api/user/login', params).pipe(
        map(r => {
          localStorage.setItem('token', r['data'].token);
          _this.loggedInUser = new User(r['data']);
          this.loginEvent.emit(true);
          return true;
        }),
        catchError(this.handleError<boolean>())
      );
  }

  // public logInSaml(user: User): Observable<boolean> {
  //   localStorage.removeItem('token');
  //   localStorage.setItem('token', user['token']);
  //   this.loggedInUser = user;
  //   this.loginEvent.emit(true);
  //   return new BehaviorSubject<boolean>(true);
  // }

  public logOut() {
    localStorage.removeItem('token');
    this.loggedInUser = null;
  }
  // #endregion

  //#region helper
  public isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token != 'undefined') {
      var expired = this.jwtHelper.isTokenExpired(token);
      if (!expired) {
        if (!this.loggedInUser) {
          this.loggedInUser = new User(this.jwtHelper.decodeToken(token));
        }
        return true;
      }
    }
    return false;
  }

  public updateLoggedInUser() {
    this.getUser(this.loggedInUser.userId).subscribe(
      (r) => {
        // console.log('Updated', r);
        if (r) {
          this.loggedInUser = r;
        }
      }
    )
  }

  public getCurrentUser(): User {
    return this.loggedInUser;
  }

  public hasRole(roles: string[]): boolean {
    if (this.loggedInUser) {
      return this.loggedInUser.roles.filter(x => roles.includes(x)).length > 0;
    } else {
      return false;
    }
  }

  public canEdit(subs: Submission[]) {
    var roles = this.loggedInUser.roles;

    for (var i = 0; i < subs.length; i++) {
      var sub = subs[i];
      var flg = false;

      if (roles.includes('PortalAdmin')) {
        // portal admin: edit any sub in any state
        flg = true;
      } else if (roles.includes('HqPortalManager')) {
        // special role for FOIA
        flg = false;
      } else {
        if (sub.workflowState != 'Closed') {
          // not editable once closed

          if (roles.includes('ProcedureDesigner') && sub.workflowState == 'IFP' && this.loggedInUser.email==sub.ProcedureDesignerEmail) {
            // designer: editable in IFP state
            flg = true;
          } else if (roles.includes('ProcedureDesigner') && sub.workflowState == 'Mgr Signature' && this.loggedInUser.email==sub.ProcedureDesignerEmail) {
            // designer: editable in Mgr Signature state
            flg = true;
          } else if (roles.includes('AirportContact') && sub.workflowState == 'Airport' && this.loggedInUser.email==sub.airportContactEmail) {
            // apt contact: editable in Airport state
            flg = true;  
          } else if (roles.includes('ServiceCenterAdmin') || (roles.includes('IFPAdmin'))) {
            // sca: editable in service center
            // envr: editable in Service Center state & in service center            
            flg = true;
          //} else if (roles.includes('EnvironmentalSpecialist') && sub.workflowState == 'Service Center' && this.loggedInUser.email==sub.EnvironmentalSpecialistEmail) {
          }  else if (roles.includes('EnvironmentalSpecialist') && sub.workflowState == 'Service Center') {
            // sca: editable in service center
            // envr: editable in Service Center state & in service center
            if (sub.serviceCenterId == this.loggedInUser.serviceCenterId) {
              flg = true;
            }
          } else if (roles.includes('ExternalSubmissionsAdmin')) {
            if (this.hasExternalEmail(sub)) {
              flg = true;
            }
          }
        }
      }
      sub['canEdit'] = flg;
    }
  }

  private hasExternalEmail(sub: Submission) {
    var roles = ['ProcedureDesigner', 'AirportContact', 'EnvironmentalSpecialist'];
    for (var i = 0; i < roles.length; i++) {
      var r = roles[i];
      var str = r + 'Email';
      var email;
      if (sub[r]) {
        email = sub[r].email;
      } else if (sub[str]) {
        email = sub[str];
      }

      if (!email.endsWith('@faa.gov')) {
        return true;
      }
    }

    return false;
  }

  public isReqAccUser(): boolean {
    if (this.loggedInUser) {
      return this.loggedInUser.email == environment.accReqUser.email;
    } else {
      return false;
    }
  }
  //#endregion

  //#region get
  public getUser(id: Number): Observable<User> {
    return this.http
      .get(API_URL + '/api/user/' + id).pipe(
        map(r => {
          if (r['data']) {
            return new User(r['data']);
          }
          return null;
        }),
        catchError(this.handleError<User>())
      );
  }

  public listUsers(role?: string, center?: string): Observable<User[]> {
    let params = new HttpParams();
    if (role) {
      params = params.append('role', role);
    }
    if (center) {
      params = params.append('serviceCenterCode', center);
    }
    return this.http
      .get(API_URL + '/api/user/list', { params: params }).pipe(
        map(r => {
          return Object.keys(r['data']).map(function (key) {
            var u = new User(r['data'][key]);
            // u.name_email = u.lastName + ', ' + u.firstName + ' (' + u.email + ')';
            return u;
          });
        }),
        catchError(this.handleError<User[]>())
      );
  }

  private handleError<T>(result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      return of(result as T);
    };
  }
  //#endregion

  //#region post
  public updateAccount(isNew: boolean, user: User): Observable<Object> {
    var userData = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      serviceCenterId: user.serviceCenterId,
      serviceCenterCode: user.serviceCenterCode,
      isActive: user.isActive
    };

    if (user.airportId) {
      userData['airportId'] = user.airportId;
    }

    if (user.password) {
      userData['password'] = user.password;
    }

    // console.log(userData);
    if (isNew) {
      return this.http.post(API_URL + '/api/user/signup', userData)
    } else {
      return this.http.put(API_URL + '/api/user/' + user.userId, userData)
    }
  }

  public assignRoles(user: User): Observable<Object> {
    var dat = {
      userId: user.userId,
      roles: user.roles
    }
    // console.log('Assign Roles: ' + user.roles);
    return this.http.post(API_URL + '/api/user/role/assign', dat);
  }

  public resetPassword(email: string): Observable<Object> {
    var params = {
      email: email
    };
    return this.http.post(API_URL + '/api/user/resetPassword', params);
  }
  //#endregion
}
