import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubmissionService } from '../shared/service/submission.service';
import { UserService } from '../shared/service/user.service';
import { Submission } from '../shared/class/submission';
import { OrderService } from '../shared/service/order.service';
import { SelectMultipleControlValueAccessor } from '@angular/forms';
import { fakeAsync } from '@angular/core/testing';

@Component({
  selector: 'app-submission-list',
  templateUrl: './submission-list.component.html',
  styleUrls: ['./submission-list.component.css']
})
export class SubmissionListComponent implements OnInit {

  @ViewChild('tt') subList: ElementRef;
  @ViewChild('searchTxt') searchTxt: ElementRef;

  submissions: Submission[];
  shownSubmissions: Submission[];
  canEdit: boolean[];
  selectedWorkflow: string;
  selectedCenter: string;
  canRestore: boolean;

  cols: Array<any> = [
    { field: 'requestId', header: 'Request ID', width: '10%' },
    { field: 'submissionId', header: 'ID', isNumeric: true },
    { field: 'airportCode', header: 'Airport' },
    { field: 'state', header: 'Airport Location (State)' },
    { field: 'navAid', header: 'NAVAID' },
    { field: 'airway', header: 'Airway' },
    { field: 'publicationDate', header: 'Pub Date', isDate: true },
    { field: 'createdAt', header: 'Created', isDate: true },
    { field: 'updatedAt', header: 'Updated', isDate: true },
    { field: 'airportContactName', header: 'Airport Contact' },
    { field: 'serviceCenter', header: 'Service Center', isServiceCenter: true },
    { field: 'procedureDesignerName', header: 'IFP' },
    { field: 'environmentalSpecialistName', header: 'Env Specialist' },
    { field: 'shpoLetterDateSent', header: 'SHPO Letter Date Sent', isDate: true },
    { field: 'workflowState', header: 'Workflow State', isWorkflow: true },
    { field: 'isCatex', header: 'CATEX Category' }
  ];

  rowCount: number = 50;
  rowCountReset: number;
  entries: Array<any> = [
    { val: 10 },
    { val: 25 },
    { val: 50 },
    { val: 100 }
  ];

  workflowReset: string;
  states: Array<any> = [
    { val: 'Open' },
    { val: 'IFP' },
    { val: 'Airport' },
    { val: 'Service Center' },
    { val: 'Closed' }
  ]

  centerReset: string;
  serviceCenters: Array<any> = [
    { str: 'All' },
    { str: 'Central', code: 'C', id: 1 },
    { str: 'Eastern', code: 'E', id: 2 },
    { str: 'Western', code: 'W', id: 3 },
    { str: 'AFS', code: 'A', id: 4 },
    { str: 'External', code: 'X', id: 5 }
  ]

  centerLabels = [
    { label: 'Central', value: 'Central' },
    { label: 'Western', value: 'Western' },
    { label: 'Eastern', value: 'Eastern' },
    { label: 'AFS', value: 'AFS' }
  ]

  workflowLabels = [
    { label: 'IFP', value: 'IFP' },
    { label: 'Open', value: 'Open' },
    { label: 'Airport', value: 'Airport' },
    { label: 'Service Center', value: 'Service Center' },
    { label: 'Mgr Signature', value: 'Mgr Signature' },
    { label: 'Closed', value: 'Closed' },
    { label: 'Hidden', value: 'Hidden' }
  ]

  constructor(private subService: SubmissionService, private userService: UserService, private orderService: OrderService) { }

  ngOnInit() {
    if (this.userService.hasRole(['PortalAdmin', 'ServiceCenterAdmin', 'ExternalSubmissionsAdmin'])) {
      this.states.push({ val: 'Hidden' });
    }
    
    // make the close archive button hidden when the page first loads up
    var x = document.getElementById("closeButton");
    x.style.display = "none";

    this.subService.getSubmissions().subscribe(
      (r) => {
        for (var i = 0; i < r.length; i++) {
          if (r[i].airportCode.startsWith('---')) {
            r[i].airportCode = '---';
          }

          var nav = r[i].navAid;
          if (r[i].navAid) {
            var str = nav.split('_');
            r[i].navAid = (str[0] + ', ' + str[1]).split('|').join('/');
          }

          r[i].createdAt = new Date(r[i].createdAt);
          r[i].updatedAt = new Date(r[i].updatedAt);
          if (r[i].publicationDate !== null) {
            r[i].publicationDate = new Date(r[i].publicationDate);
          }
          if (r[i].shpoLetterDateSent !== null) {
            r[i].shpoLetterDateSent = new Date(r[i].shpoLetterDateSent);
          }
        }

        this.submissions = r;
        this.userService.canEdit(this.submissions);
        this.setServiceCenter();
        this.selectedCenter = 'All';
        this.selectedWorkflow = 'Open';

        var data = this.orderService.getOption();
        var user = this.userService.getCurrentUser();

        if (data.rowFlag > 0) {

          this.submissions = r;
          this.rowCount = data.rowCount;
          this.rowCountReset = data.rowCount;
          this.shownSubmissions = this.submissions.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete');

        } else {
         if (user.email.includes("@faa.gov")) {
          if (user.roles.indexOf('PortalAdmin') >= 0 || user.roles.indexOf('HqPortalManager') >= 0) {
            this.shownSubmissions = r.filter(x => x.workflowState != 'Archive' && x.workflowState != 'Delete');
          } else if(user.roles.indexOf('ExternalSubmissionsAdmin') >= 0 || user.roles.indexOf('ExternalSubmissionsManager') >= 0) { 
            this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && (x.workflowState=='Service Center' || x.workflowState=='IFP' || x.workflowState=='Mgr Signature' || x.workflowState=='Closed'));
          } else if(user.roles.indexOf('ServiceCenterAdmin') >= 0 && user.serviceCenterId==4) { // for AFS service center managers
            this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && (x.workflowState=='Service Center' || x.workflowState=='Closed' || (x.workflowState=='IFP' && (x.serviceCenterId==4 || x.serviceCenterId==5))));
          } else if(user.roles.indexOf('ServiceCenterAdmin') >= 0 || user.roles.indexOf('ServiceCenterManager') >= 0 || user.roles.indexOf('EnvironmentalSpecialist') >= 0) {
            this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && (x.workflowState=='Service Center' || x.workflowState=='Closed'));
          } else {
            this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && ((x.ProcedureDesignerEmail!=null && x.ProcedureDesignerEmail.includes(user.email) && user.roles.includes('ProcedureDesigner')) || (x.airportContactEmail!=null && x.airportContactEmail.includes(user.email) && user.roles.includes('AirportContact'))));
          }
          } else {
            const parts = user.email.split('@');
            if(user.roles.indexOf('ExternalSubmissionsAdmin') >= 0 || user.roles.indexOf('ExternalSubmissionsManager') >= 0 || user.roles.indexOf('EnvironmentalSpecialist') >= 0) {
             // this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && (x.serviceCenterId==4 || x.serviceCenterId==5)); 
              this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && (x.workflowState=='Service Center' || x.workflowState=='Mgr Signature' || x.workflowState=='Closed') && (x.serviceCenterId==4 || x.serviceCenterId==5)); 
            } else if(user.roles.indexOf('PortalAdmin') >= 0 || user.roles.indexOf('HqPortalManager') >= 0){
              this.shownSubmissions = r.filter(x => x.workflowState != 'Archive' && x.workflowState != 'Delete');
            } else {
              this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete' && ((x.ProcedureDesignerEmail!=null && x.ProcedureDesignerEmail.includes(parts[1]) || (x.airportContactEmail!=null && x.airportContactEmail.includes(parts[1]) ))));
            }
          }
        }

        var g = this.subList['filters']['global'];
        if (g) {
          this.searchTxt.nativeElement.value = g.value;
        }
      }
    )
  }

  setServiceCenter() {
    for (var i = 0; i < this.submissions.length; i++) {
      var sub = this.submissions[i];
      if (sub.serviceCenterId) {
        sub['serviceCenter'] = this.serviceCenters.find(x => x.id == sub.serviceCenterId)['str'];
      }

      const types = [];
      if (sub.workflowState == 'Closed') {
        if (sub.isCatexEligible == false) {
          sub['isCatex'] = 'No';
        } else if (sub.isCatexEligible == null) {
          sub['isCatex'] = 'N/A';
        } else {
          if (sub.IsCATEX_h == true) { types.push('H'); }
          if (sub.IsCATEX_i == true) { types.push('I'); }
          if (sub.IsCATEX_j == true) { types.push('J'); }
          if (sub.IsCATEX_k == true) { types.push('K'); }
          if (sub.IsCATEX_p == true) { types.push('P'); }
          if (sub.IsCATEX_b == true) { types.push('B'); }
          sub['isCatex'] = types;
        }
      } else {
        if (sub.IsCATEX_h == true) { types.push('H'); }
        if (sub.IsCATEX_i == true) { types.push('I'); }
        if (sub.IsCATEX_j == true) { types.push('J'); }
        if (sub.IsCATEX_k == true) { types.push('K'); }
        if (sub.IsCATEX_p == true) { types.push('P'); }
        if (sub.IsCATEX_b == true) { types.push('B'); }
        sub['isCatex'] = types;
      }
    }
  }

  updateRowCount(event) {
    var n = event.value.val ? event.value.val : event.value;
    this.rowCount = n;
    this.orderService.setOption('rowCount', n);
    this.orderService.setOption('rowFlag', 2);
  }

  updateSubmissionsFilter() {
    var subs: Submission[];
    if (this.selectedWorkflow == 'Open') {
      subs = this.submissions.filter(x => x.workflowState != 'Closed' && x.workflowState != 'Hidden');
    } else {
      subs = this.submissions.filter(x => x.workflowState == this.selectedWorkflow);
    }

    if (this.selectedCenter == 'All') {
      this.shownSubmissions = subs;
    } else {
      this.shownSubmissions = subs.filter(x => x['serviceCenter'] == this.selectedCenter);
    }
  }

  resetSubList() {
    this.searchTxt.nativeElement.value = '';
    this.subList['filterGlobal'](null, 'contains');
    this.subList['reset']();
  }

  exportSubmission() {
    var data = this.subList['filteredValue'] == null ? this.subList['value'] : this.subList['filteredValue'];
    var csvData = this.generateCsv(data);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'submissions.csv';
    a.click();
  }

  archiveSubmission(){
    var x = document.getElementById("closeButton");
    x.style.display = "block";
    var x = document.getElementById("archiveButton");
    x.style.display = "none";

    this.subService.getSubmissions().subscribe(
      (r) => {
        for (var i = 0; i < r.length; i++) {
          if (r[i].airportCode.startsWith('---')) {
            r[i].airportCode = '---';
          }

          var nav = r[i].navAid;
          if (r[i].navAid) {
            var str = nav.split('_');
            r[i].navAid = (str[0] + ', ' + str[1]).split('|').join('/');
          }

          r[i].createdAt = new Date(r[i].createdAt);
          r[i].updatedAt = new Date(r[i].updatedAt);
          if (r[i].publicationDate !== null) {
            r[i].publicationDate = new Date(r[i].publicationDate);
          }
          if (r[i].shpoLetterDateSent !== null) {
            r[i].shpoLetterDateSent = new Date(r[i].shpoLetterDateSent);
          }
        }

        this.submissions = r;
        this.canRestore = true;
        this.setServiceCenter();
        this.selectedCenter = 'All';
        this.selectedWorkflow = 'Open';

        var data = this.orderService.getOption();

        if (data.rowFlag > 0) {

          this.submissions = r;
          this.rowCount = data.rowCount;
          this.rowCountReset = data.rowCount;
          this.shownSubmissions = this.submissions.filter(x => x.workflowState == 'Archive');

        } else {
          this.shownSubmissions = r.filter(x => x.workflowState == 'Archive');
        }

        var g = this.subList['filters']['global'];
        if (g) {
          this.searchTxt.nativeElement.value = g.value;
        }
      }
    )
  }

  returntoSubmission(){
    var x = document.getElementById("closeButton");
    x.style.display = "none";
    var x = document.getElementById("archiveButton");
    x.style.display = "block";

    this.subService.getSubmissions().subscribe(
      (r) => {
        for (var i = 0; i < r.length; i++) {
          if (r[i].airportCode.startsWith('---')) {
            r[i].airportCode = '---';
          }

          var nav = r[i].navAid;
          if (r[i].navAid) {
            var str = nav.split('_');
            r[i].navAid = (str[0] + ', ' + str[1]).split('|').join('/');
          }

          r[i].createdAt = new Date(r[i].createdAt);
          r[i].updatedAt = new Date(r[i].updatedAt);
          if (r[i].publicationDate !== null) {
            r[i].publicationDate = new Date(r[i].publicationDate);
          }
          if (r[i].shpoLetterDateSent !== null) {
            r[i].shpoLetterDateSent = new Date(r[i].shpoLetterDateSent);
          }
        }

        this.submissions = r;
        this.userService.canEdit(this.submissions);
        this.canRestore = false;
        this.setServiceCenter();
        this.selectedCenter = 'All';
        this.selectedWorkflow = 'Open';

        var data = this.orderService.getOption();

        if (data.rowFlag > 0) {

          this.submissions = r;
          this.rowCount = data.rowCount;
          this.rowCountReset = data.rowCount;
          this.shownSubmissions = this.submissions.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete');

        } else {
          this.shownSubmissions = r.filter(x => x.workflowState != 'Hidden' && x.workflowState != 'Archive' && x.workflowState != 'Delete');
        }

        var g = this.subList['filters']['global'];
        if (g) {
          this.searchTxt.nativeElement.value = g.value;
        }
      }
    )
  }

  restoreSubmission(submissionId) {
     // change the workflow state back to what it was previously
    this.subService.getSubmission(submissionId).subscribe(
      (r) => {
        r.workflowState=r.prevWorkflowState;

        this.subService.updateSubmission(r).subscribe()
      }
    )

  // make this part wait so that the workflow state is changed on the submission before the table is filtered again
    setTimeout(() => 
     this.subService.getSubmissions().subscribe(
    (r) => {
      this.shownSubmissions = r.filter(x => x.workflowState == 'Archive');
    }
  ), 500); 

  }

  generateCsv(data): string {
    var str = '';
    var header = this.cols.map(x => x.header).join(',');
    str += header + '\r\n';

    var fields = this.cols.map(x => x.field);
    var isDate = this.cols.map(x => x.isDate);

    for (var i = 0; i < data.length; i++) {
      var line = '';
      for (var j = 0; j < fields.length; j++) {
        var f = fields[j];
        var dat = data[i][f];

        if (isDate[j]) {
          if (dat != null) {
            dat = this.getDate(dat);
          }
        }

        if (dat == null) {
          dat = ' ';
        }

        if (typeof dat == 'string' && dat.includes(',')) {
          dat = '"' + dat + '"';
        }
        line += dat + ',';
      }
      line = line.slice(0, -1);
      str += line + '\r\n';
    }
    return str;
  }

  getDate(d: Date) {
    var yy = d.getUTCFullYear().toString();
    var month = d.getUTCMonth() + 1;
    var mm = month < 10 ? '0' + month : month;
    var day = d.getUTCDate();
    var dd = day < 10 ? '0' + day : day;

    var hour = d.getUTCHours();
    var hh = hour < 10 ? '0' + hour : hour;
    var minute = d.getUTCMinutes();
    var m = minute < 10 ? '0' + minute : minute;

    return mm + '/' + dd + '/' + yy; // + ' ' + hh + ':' + m;
  }


}
