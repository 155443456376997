import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { AdminComponent } from './admin/admin.component';
import { SubmissionManageComponent } from './submission-manage/submission-manage.component';
import { SubmissionListComponent } from './submission-list/submission-list.component';
import { SubmissionViewComponent } from './submission-view/submission-view.component';
import { CatexComponent } from './catex/catex.component';

import { CanActivateGuardService } from './shared/service/canactivateguard.service';
import { CanDeActivateGuardService } from './shared/service/candeactivateguard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [CanActivateGuardService],
    children: [
      {
        path: 'home',
        component: HomeComponent
      }, {
        path: 'about',
        component: AboutComponent
      }, {
        path: 'news',
        component: NewsComponent
      }, {
        path: 'submissions',
        component: SubmissionListComponent
      }, {
        path: 'catex/:id',
        component: CatexComponent
      }, {
        path: 'addsubmission',
        component: SubmissionManageComponent,
        canDeactivate: [CanDeActivateGuardService]
      }, {
        path: 'editsubmission/:id',
        component: SubmissionManageComponent,
        canDeactivate: [CanDeActivateGuardService]
      }, {
        path: 'viewsubmission/:id',
        component: SubmissionViewComponent
      }, {
        path: 'submissions',
        component: SubmissionListComponent
      }, {
        path: 'account',
        component: UserManageComponent
      }, {
        path: 'user/:id',
        component: UserManageComponent
      }, {
        path: 'user',
        component: UserManageComponent
      }, {
        path: 'admin',
        component: AdminComponent
      }, {
        path: 'requestaccount',
        component: UserManageComponent
      }
    ]
  }, {
    path: 'login',
    component: LoginComponent
  }, 
  // {
  //   path: 'login/:param',
  //   component: LoginComponent
  // },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
