//import { PARAMETERS } from "@angular/core/airportsrc/util/decorators";
import { PARAMETERS } from "@angular/core/esm2022/src/util/decorators.mjs";

export class Submission {
    // base
    procedureDesignerId: number;
    ProcedureDesignerEmail: string;
    ProcedureDesigner: any;
    submissionId: number;
    requestId: string;
    airportId: number;
    airportCode: string;
    navAid: string;
    airway: string;
    serviceCenterId: number;
    publicationDate: Date;
    airportContactId: number;
    airportContactEmail: string;
    AirportContact: any;
    EnvironmentalSpecialist: any;

    createdAt: Date;
    updatedAt: Date;
    // publicationDateStr: string;
    // createdAtStr: string;
    // updatedAtStr: string;

    // revision
    workflowState: string;
    prevWorkflowState: string;
    revisionMessage: string;

    // ifp
    procedureRequestType: string; // new or ammended
    isSingle: boolean;
    procedureName: string;
    procedureDescription: string;
    procedureBenefit: string;
    procedureNeed: string;
    otherReason: string;

    isApproachProcedure: boolean;
    isDepartureProcedure: boolean;
    isEnrouteProcedure: boolean;

    isOnlyHelicopterOps: boolean;
    isEmergencyHeloRoute: boolean;
    procedureAltitude: string;
    trackCoordinateInput: string;

    // amended procedures
    isChangeLinesOfMinimum: boolean;
    isAltitudeIncreases: boolean;
    isIfrTakeoffAndDPs: boolean;
    isMinimumSafeAltitude: boolean;
    isChangeToCircling: boolean;
    isArrivalHolding: boolean;
    isVisualClimbOverAirport: boolean;
    isOther: boolean;
    isNameChanges: boolean;
    isAmendingNotes: boolean;
    isMagneticVariationAdjustments: boolean;
    isCodingChanges: boolean;
    isCancellationIFPs: boolean;
    isMissedApproaches: boolean;
    isOnlyChangesToProcedure: boolean;

    // airport
    numHelicopterDayOps: number;
    numHelicopterEveningOps: number;
    numHelicopterNightOps: number;
    numPropsDayOps: number;
    numPropsEveningOps: number;
    numPropsNightOps: number;
    numJetsDayOps: number;
    numJetsEveningOps: number;
    numJetsNightOps: number;

    isResidential: boolean;
    isEducational: boolean;
    isHospital: boolean;
    isReligiousStructure: boolean;
    isRecreational: boolean;
    isCulturalSites: boolean;
    isParkNotNational: boolean;
    isWildernessNotNational: boolean;
    isWildlifeNotNational: boolean;
    isNationalPark: boolean;
    isNationalWilderness: boolean;
    isNationalWildlifeRefuge: boolean;
    isUnknown: boolean;
    isNone: boolean;

    airportContactNotes: string;

    // envr
    environmentalSpecialistId: number;
    EnvironmentalSpecialistEmail: string;
    historicProperties: string;
    shpoLetterDateSent: Date;
    procedureDescriptionForCATEX: string;
    environmentalSpecialistNotes: string;
    FilterToolRecommendation: string;
    isCatexEligible: boolean;
    IsCATEX_h: boolean;
    IsCATEX_i: boolean;
    IsCATEX_j: boolean;
    IsCATEX_k: boolean;
    IsCATEX_p: boolean;
    IsCATEX_b: boolean;
    
    // community engagement specialist information
    isReviewed: boolean;
    reviewDate: Date;
    reviewerName: string;

    isPrivate: boolean;
    ProcedureDetails: any[];

    constructor(values: Object = {}) {
        // this.addValues(values);
        Object.assign(this, values);
    }

    // addValues(values: Object) {
    //     var str = ['publicationDate', 'createdAt', 'updatedAt', 'shpoLetterDateSent'];
    //     for (var i = 0; i < str.length; i++) {
    //         var x = values[str[i]];
    //         if (typeof x == 'string') {
    //             var d = new Date(x);
    //             values[str[i]] = d;
    //             values[str[i] + 'Str'] = this.getDate(d);
    //         }
    //     }
    // }

    // getDate(d: Date) {
    //     var yy = d.getUTCFullYear().toString().substr(2, 2);
    //     var month = d.getUTCMonth() + 1;
    //     var mm = month < 10 ? '0' + month : month;
    //     var day = d.getUTCDate();
    //     var dd = day < 10 ? '0' + day : day;
    //     return mm + '/' + dd + '/' + yy;
    // }
}
