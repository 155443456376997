// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
    color: #337ab7;
    font-size: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.btn-default {
    margin-left: 10px;
    margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/news/news.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".link {\r\n    color: #337ab7;\r\n    font-size: 30px;\r\n    display: inline-block;\r\n    margin-top: 15px;\r\n    margin-bottom: 10px;\r\n    margin-right: 10px;\r\n}\r\n\r\n.btn-default {\r\n    margin-left: 10px;\r\n    margin-bottom: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
