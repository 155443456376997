import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../shared/service/user.service';
import { User } from '../shared/class/user';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // form
  loginForm: FormGroup;
  loginEmail: FormControl;
  loginPassword: FormControl;
  invalidLogin: boolean;
  loginAttempted = false;
  showWarning = false;
  showPasswordReset = false;
  showPasswordResetSuccess = false;
  showPasswordResetFail = false;
  showNoAccount = false;
  invalidEmail: string;

  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    // this.route.params.subscribe(
    //   (r) => {
    //     if (r.param) {
    //       try {
    //         var dat = JSON.parse(r.param);
    //         if (dat.userId && dat.firstName && dat.lastName) {
    //           var user = new User(JSON.parse(r.param));
    //           user['token'] = dat.token;
    //           this.userService.logInSaml(user).subscribe(
    //             (r) => {
    //               this.router.navigate(['/home']);
    //               this.invalidLogin = false;
    //             });
    //         } else {
    //           this.invalidEmail = dat.email;
    //           this.showNoAccount = true;
    //         }
    //       } catch (error) {
    //         this.router.navigate(['/login']);
    //         this.invalidLogin = true;
    //       }
    //     }
    //   })

    this.loginEmail = new FormControl('', [Validators.required, Validators.email]);
    this.loginPassword = new FormControl('', Validators.required);

    this.loginForm = new FormGroup({
      loginEmail: this.loginEmail,
      loginPassword: this.loginPassword
    });

    if (this.userService.isLoggedIn()) {
      this.router.navigate(['/home']);
    }

    if (!window['chrome'] || !!window['StyleMedia']) {
      this.showWarning = true;
    }

    this.loginEmail.valueChanges.subscribe(
      (r) => {
        this.showPasswordReset = !this.loginEmail.invalid;
      }
    )
  }

  onLogin() {
    this.loginAttempted = true;
    var user = new User();
    user.email = this.loginEmail.value;
    user.password = this.loginPassword.value;
    this.userService.logIn(user).subscribe(
      (r) => {
        if (r) {
          this.router.navigate(['/home']);
          this.invalidLogin = false;
        } else {
          this.invalidLogin = true;
        }
      });
  }

  isValid() {
    if (this.loginAttempted) {
      return this.invalidLogin ? 'has-error' : 'has-success';
    } else {
      return null;
    }
  }

  requestNewAccount() {
    var user = new User();
    user.email = environment.accReqUser.email;
    user.password = environment.accReqUser.password;
    this.userService.logIn(user).subscribe(
      (r) => {
        if (r) {
          this.router.navigate(['/requestaccount']);
          this.invalidLogin = false;
        } else {
          this.invalidLogin = true;
        }
      });
  }

  requestNewPassword() {
    this.userService.resetPassword(this.loginEmail.value).subscribe(
      (r) => {
        this.showPasswordResetSuccess = true;
      },
      (err) => {
        this.showPasswordResetFail = true;
      }
    );
  }

  resetParams() {
    this.showPasswordResetSuccess = false;
    this.showPasswordResetFail = false;
  }

  // resetParams(toLogin) {
  //   this.showPasswordResetSuccess = false;
  //   this.showPasswordResetFail = false;
  //   this.showNoAccount = false;
  //   if (toLogin == '1') {
  //     this.router.navigate(['/login']);
  //   }
  // }
}
