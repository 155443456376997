export class Navaid {
    uniqueId: string;
    id: string;
    name: string;
    type: string;
    city: string;
    state: string;
    country: string;
    longitude: number;
    latitude: number;
    label: string;

    constructor(values: Object = {}) {
        var str = values['state'] ? values['state'] : values['country'];
        str = values['id'] + ', ' + values['name'] + ', ' + values['type'] +
            ', ' + values['city'] + ', ' + str + ' (' + values['latitude'].toPrecision(5) + '/' + values['longitude'].toPrecision(5) + ')';
        values['label'] = str.split('|').join('/');

        Object.assign(this, values);
        Object.assign(this, values);
    }
}
