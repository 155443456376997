// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel {
    width: 300px;
    margin-top: 15px;
    margin-bottom: 0px;
    margin-left: 20px;
}

.panel-body {
    padding: 7px;
}

.inc-top {
    margin-top: 10px;
}

.form-btn {
    margin-bottom: 30px;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/user-manage/user-manage.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".panel {\r\n    width: 300px;\r\n    margin-top: 15px;\r\n    margin-bottom: 0px;\r\n    margin-left: 20px;\r\n}\r\n\r\n.panel-body {\r\n    padding: 7px;\r\n}\r\n\r\n.inc-top {\r\n    margin-top: 10px;\r\n}\r\n\r\n.form-btn {\r\n    margin-bottom: 30px;\r\n    margin-top: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
