import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { SubmissionManageComponent } from './submission-manage/submission-manage.component';
import { SubmissionListComponent } from './submission-list/submission-list.component';
import { SubmissionViewComponent } from './submission-view/submission-view.component';
import { CatexComponent } from './catex/catex.component';

import { DataService } from './shared/service/data.service';
import { AirportService } from './shared/service/airport.service';
import { UserService } from './shared/service/user.service';
import { SubmissionService } from './shared/service/submission.service';
import { AuthService } from './shared/service/auth.service';
import { CatexService } from './shared/service/catex.service';
import { CoordinateService } from 'app/shared/service/coordinate.service';

import { JwtModule } from '@auth0/angular-jwt';
import { CanActivateGuardService } from './shared/service/canactivateguard.service';
import { CanDeActivateGuardService } from './shared/service/candeactivateguard.service';

import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';

import { TableModule } from 'primeng/table';
import { AdminComponent } from './admin/admin.component';
import { MtPipe } from './mt.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    AboutComponent,
    NewsComponent,
    UserManageComponent,
    SubmissionManageComponent,
    SubmissionListComponent,
    SubmissionViewComponent,
    CatexComponent,
    AdminComponent,
    MtPipe
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TableModule, DropdownModule, CalendarModule, TabViewModule, PanelModule,
    FileUploadModule, DialogModule, ConfirmDialogModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function () {
          return localStorage.getItem('token');
        }
      }
    }),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })
  ],
  providers: [
    DataService,
    AirportService,
    UserService,
    SubmissionService,
    CatexService,
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true
    },
    CanActivateGuardService,
    CanDeActivateGuardService,
    ConfirmationService,
    CoordinateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
