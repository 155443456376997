import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../shared/service/submission.service';
import { UserService } from '../shared/service/user.service';
import { AirportService } from 'app/shared/service/airport.service';
import { CoordinateService } from '../shared/service/coordinate.service';
import { Submission } from 'app/shared/class/submission';
import { User } from 'app/shared/class/user';
import { Map, TileLayer, LatLng, GeoJSON, Icon, Marker, Polyline, FeatureGroup, canvas, Popup } from 'leaflet';
const { jsPDF } = require("jspdf");
import html2pdf from 'html2pdf.js'
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
declare var omnivore: any;
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Component({
  selector: 'app-submission-view',
  templateUrl: './submission-view.component.html',
  styleUrls: ['./submission-view.component.css']
})
export class SubmissionViewComponent implements OnInit {

  //#region init variables
  @ViewChild('mapDiv') mapDiv: ElementRef;
  map: Map;
  kmlLayer: GeoJSON;
  coordLayer: FeatureGroup;

  submission: Submission;
  designer: User;
  currentUser: User;
  aptCode: string;
  navAid: string;
  airway: string;
  aptContact: string;
  isAmended: boolean;
  procedureType: string;
  onlyHelicopter: string;
  emergencyHelo: string;
  trackCoordinateInput: string;
  auth: string;
  envrSpecialist: string;
  catexEligible: string;
  reviewed: string;
  isAFS: boolean;
  otherExp: string;

  consensusFile: Array<any> = [];
  graphicFile: Array<any> = [];
  kmlFile: Array<any> = [];
  additionalFile: Array<any> = [];
  airportFile: Array<any> = [];
  envrFile: Array<any> = [];

  fullName: Array<any> = [];
  fullDescription: Array<any> = [];

  newOrRevised: string[];
  newOrRevisedOptions: Array<any> = [
    { label: 'Changes to and/or additional Lines of Minimum', val: 'isChangeLinesOfMinimum' },
    { label: 'Altitude increases', val: 'isAltitudeIncreases' },
    { label: 'IFR Takeoff Minimums and (Obstacle) Departure Procedure (ONLY applies to close-in obstacle note and/or no track changes)', val: 'isIfrTakeoffAndDPs' },
    { label: 'Other', val: 'isOther' },
    { label: 'Minimum Safe Altitudes', val: 'isMinimumSafeAltitude' },
    { label: 'Changes to circling areas', val: 'isChangeToCircling' },
    { label: 'Arrival holding patterns, not including Hold in Lieu of a Procedure Turn', val: 'isArrivalHolding' },
    { label: 'Visual Climb Over Airport (VCOA) without a route', val: 'isVisualClimbOverAirport' }
  ];

  pubActions: string[];
  pubActionsOptions: Array<any> = [
    { label: 'Name changes (Airport, Fix, Procedure, etc.)', val: 'isNameChanges' },
    { label: 'Adding, amending, removing notes to procedures', val: 'isAmendingNotes' },
    { label: 'Magnetic Variation (MagVar) adjustments', val: 'isMagneticVariationAdjustments' },
    { label: 'Coding changes with no track/altitude changes', val: 'isCodingChanges' },
    { label: 'Cancellation of IFPs not currently flown', val: 'isCancellationIFPs' }
  ]

  noiseAreas: string[];
  noiseAreasOptions: Array<any> = [
    { label: 'Residential', val: 'isResidential' },
    { label: 'Educational', val: 'isEducational' },
    { label: 'Hospital', val: 'isHospital' },
    { label: 'Religious Structure or Sites', val: 'isReligiousStructure' },
    { label: 'Recreational', val: 'isRecreational' },
    { label: 'Cultural Sites', val: 'isCulturalSites' },
    { label: 'Park (Not National)', val: 'isParkNotNational' },
    { label: 'Wilderness Area (Not National)', val: 'isWildernessNotNational' },
    { label: 'Wildlife Refuge (Not National)', val: 'isWildlifeNotNational' },
    { label: 'National Park', val: 'isNationalPark' },
    { label: 'National Wilderness Area', val: 'isNationalWilderness' },
    { label: 'National Wildlife Refuge', val: 'isNationalWildlifeRefuge' },
    { label: 'Unknown', val: 'isUnknown' },
    { label: 'None', val: 'isNone' }
  ];

  catex: string[];

  commentLogs: Array<any>;
  cols: Array<any> = [
    { field: 'submittedTime', header: 'Submitted', width: '20%', isDate: true },
    { field: 'comment', header: 'Comments', width: '80%' }
  ];
  showLog = false;
  commentForm: FormGroup;
  commentMessage: FormControl;
  //#endregion

  //#region init
  constructor(private subService: SubmissionService,
    private userService: UserService,
    private airportService: AirportService,
    private coordinateService: CoordinateService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.initSubmission();
    this.commentMessage = new FormControl();
    this.commentForm = new FormGroup({
      commentMessage: this.commentMessage
    })
  }

  ngAfterViewChecked() {
    if (this.mapDiv && !this.map) {
      // initialize map    
      this.map = new Map(this.mapDiv.nativeElement, {
        scrollWheelZoom: false,
        renderer: canvas()
      });
      this.map.setView(new LatLng(39.8283, -98.5795), 4.5);
      this.map.addLayer(new TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        { maxZoom: 18 },)
      );
      this.map.setMinZoom(3.5);
      this.map.on('focus', function (a) {
        a.target.scrollWheelZoom.enable();
      });
      this.map.on('blur', function (a) {
        a.target.scrollWheelZoom.disable();
      });

      if (this.trackCoordinateInput) {
        this.plotTrackFromCoord(this.trackCoordinateInput)
      }

      if (this.kmlFile.length > 0) {
        var path = this.kmlFile[0].fileName;
        // var path = "../../assets/files/test.kml"; // for local testing
        this.subService.readFile(path).subscribe(
          (r) => {
            this.plotTrackFromKml(r);
          });
      }
    }
  }

  initSubmission() {
    this.route.params.subscribe(
      (r) => {
        this.subService.getSubmission(r.id).subscribe(
          (r) => {
            if (!r) {
              this.router.navigate(['/home']);
            } else {
              this.extractFields(r);
            }
          }
        )
      })
  }

  replaceAll(dat, str0, str1) {
    return dat.split(str0).join(str1);
  }

  extractFields(data) {
    this.submission = data;
    this.auth = localStorage.token;

    // base
    var val = data['ProcedureDesigner'];
    this.designer = new User(val);

    this.aptCode = this.submission.airportCode;
    if (this.aptCode.startsWith('---')) {
      this.aptCode = '';
    }

    var navAid = this.submission.navAid;
    if (navAid) {
      this.airportService.getNavaid(navAid).subscribe(
        (r) => {
          this.navAid = [r][0]['label'];
        });
    }

    var airway = this.submission.airway;
    if (airway) {
      this.airportService.getAirway(airway).subscribe(
        (r) => {
          this.airway = [r][0]['id'];
        });
    }

    var val = data['AirportContact'];
    if (val) {
      if (val['firstName']) {
        this.aptContact = val.lastName + ', ' + val.firstName;
      }
    }

    // ifp
    this.isAmended = this.submission.procedureRequestType == 'Amended';

    this.currentUser = this.userService.getCurrentUser();
    if (this.currentUser.serviceCenterId==4 || this.currentUser.serviceCenterId==5 || this.userService.hasRole(['ServiceCenterAdmin', 'PortalAdmin','ServiceCenterManager'])) {
      this.isAFS=true;
    } else {
      this.isAFS=false;
    }

    this.newOrRevised = this.newOrRevisedOptions
      .map(x => this.submission[x.val] ? x.label : null)
      .filter(x => x != null);

    if (this.submission.isOther) {
      this.otherExp = " - " + this.submission.otherReason;
    } else {
      this.otherExp ="";
    }

    this.pubActions = this.pubActionsOptions
      .map(x => this.submission[x.val] ? x.label : null)
      .filter(x => x != null);


    var types = [];
    if (this.submission.isApproachProcedure) {
      types.push('Approach');
    }

    if (this.submission.isDepartureProcedure) {
      types.push('Departure');
    }

    if (this.submission.isEnrouteProcedure) {
      types.push('Enroute');
    }
    if (types.length > 0) {
      this.procedureType = types.join(', ');
    }

    if (this.submission.isOnlyHelicopterOps != null) {
      this.onlyHelicopter = this.submission.isOnlyHelicopterOps ? 'Yes (Helicopter ONLY)' : 'No (Involves Fixed Wing Aircraft)';
    }

    if (this.submission.isEmergencyHeloRoute != null) {
      this.emergencyHelo = this.submission.isEmergencyHeloRoute ? 'Yes' : 'No';
    }

    this.trackCoordinateInput = this.submission.trackCoordinateInput;

    var uploaded = data.SubmissionFiles;
    this.consensusFile = uploaded.filter(x => x.FileType.fileTypeId == 1);
    this.graphicFile = uploaded.filter(x => x.FileType.fileTypeId == 2);
    this.kmlFile = uploaded.filter(x => x.FileType.fileTypeId == 3);
    this.additionalFile = uploaded.filter(x => x.FileType.fileTypeId == 4);
    this.airportFile = uploaded.filter(x => x.FileType.fileTypeId == 5);
    this.envrFile = uploaded.filter(x => x.FileType.fileTypeId == 6);

    // airport
    this.noiseAreas = this.noiseAreasOptions
      .map(x => this.submission[x.val] ? x.label : null)
      .filter(x => x != null);

    // envr
    var envr = data['EnvironmentalSpecialist'];
    if (envr) {
      this.envrSpecialist = envr['lastName'] + ', ' + envr['firstName'];
    }

    // catex
    this.catex = [];
    var code = ['h', 'i', 'j', 'k', 'p', 'b'];
    for (var i = 0; i < code.length; i++) {
      if (this.submission['IsCATEX_' + code[i]]) {
        this.catex.push('CATEX 5-6.5.' + code[i]);
      }
    }

    this.catexEligible = this.submission.isCatexEligible == null ? 'N/A' : (this.submission.isCatexEligible ? 'Yes' : 'No');
    
    this.reviewed = this.submission.isReviewed == null ? 'N/A' : (this.submission.isReviewed ? 'Yes' : 'No');

    this.commentLogs = data.SubmissionComments.filter(x => !x.isRevisionLog);
    if (this.commentLogs.length > 0) {
      this.showLog = true;
    }

    if (this.submission.ProcedureDetails.length>0) {
      this.fullName[0]='1) ' + this.submission.procedureName;
    } else {
      this.fullName[0]=this.submission.procedureName;
    }

    for (var i = 0; i < this.submission.ProcedureDetails.length; i++) {
      this.fullName[i+1]=(i+2) + ') ' + this.submission.ProcedureDetails[i].procedureName;  
    } 
    
    if (this.submission.ProcedureDetails.length>0) {
      this.fullDescription[0]=('1) ' + this.submission.procedureName + '; ' + this.submission.procedureDescription);
    } else {
      this.fullDescription[0]=(this.submission.procedureName + '; ' + this.submission.procedureDescription);
    }

    for (var i = 0; i < this.submission.ProcedureDetails.length; i++) {
      this.fullDescription[i+1]=((i+2) + ') ' +this.submission.ProcedureDetails[i].procedureName + '; ' + this.submission.ProcedureDetails[i].procedureDescription);  
    } 
  }
  //#endregion

  //#region map
  getIcon() {
    var m = 18;
    var icon = new Icon({
      iconUrl: '../../assets/images/circle_grey.png',
      iconSize: [m, m],
      iconAnchor: [m / 2, m / 2]
    });

    return icon;
  }

  plotTrackFromKml(str) {
    if (this.kmlLayer) {
      this.kmlLayer.remove();
    }

    var icon = this.getIcon();
    this.kmlLayer = new GeoJSON(null, {
      pointToLayer: function (geoJsonPoint, latlng) {
        if (geoJsonPoint.properties.name) {
          var m = new Marker(latlng, {
            icon: icon,
            title: geoJsonPoint.properties.name
          })

          m.on('mouseover', function (e) {
            new Popup().setLatLng(e['latlng']).setContent(e.target.options.title).openOn(this._map);
          });

          return m;
        } else {
          return new Marker(latlng, {
            icon: icon
          })
        }
      }
    });


    omnivore.kml.parse(str, null, this.kmlLayer).addTo(this.map);
    this.map.fitBounds(this.kmlLayer.getBounds());
  }

  plotTrackFromCoord(val) {
    if (this.map) {
      if (this.coordLayer) {
        this.coordLayer.remove();
      }

      var coord = this.coordinateService.parseTrackCoordinate(val);

      if (coord && coord.length > 1) {
        var markers = [];
          for (var i = 0; i < coord.length; i++) {
            var m;
            if (coord[i].length == 3) {
              m = new Marker(coord[i].slice(0, 2), {
                icon: this.getIcon(),
                title: coord[i][2]
              });

              m.on('mouseover', function (e) {
                new Popup().setLatLng(e['latlng']).setContent(e.target.options.title).openOn(this._map);
              });
            } else {
              m = new Marker(coord[i].slice(0, 2), {
                icon: this.getIcon()
              });
            }
            markers.push(m);
          }

         this.coordLayer = new FeatureGroup(markers)
           .addLayer(new Polyline(coord, { 'color': '#235fb2',renderer: canvas() }))
           .addTo(this.map);
         this.map.fitBounds(this.coordLayer.getBounds());
      }
    }
  }
  //#endregion

  //#region comment
  hasComment() {
    return this.commentMessage.value != null && this.commentMessage.value.length > 0;
  }

  saveComment() {
    this.subService.addComment(this.submission.submissionId, this.commentMessage.value, false).subscribe(
      (x) => {
        this.router.navigate(['/submissions']);
      }
    )
  }

  cancelComment() {
    this.commentMessage.setValue('');
  }
  //#endregion

  //#region helper
  showCatexForm() {
    this.router.navigate(['/catex', this.submission.submissionId]);
  }
  //#endregion

  // export the procedure and all attached files to PDF
  exportProcedure(){
    var element=document.getElementById('divToExport'); // I added the id divToExport to the top div element in the html to grab everything
    var outputName= 'EPFT Submission ' + this.submission.requestId + '.pdf';

    var opt = {
      margin:       [1,0,1,0],
      filename:     outputName,
      image:        {type: 'jpeg',quality:1},
      html2canvas:  {scale: 1,useCORS: true,allowTaint:true},
      pagebreak:    {mode: 'avoid-all'},
      jspdf:        {unit: 'in',format: 'letter', orientation: 'portrait',precision: '12'}
     };

    let doc = html2pdf().set(opt).from(element).toPdf()
    //doc.save()

    var zip = new JSZip();
    var zipFileName= this.submission.requestId + '.zip';

    var zip = new JSZip();
    zip.file(outputName, doc.output());

    // add the additional files attached to the submission to the zip file
    var numFiles = this.additionalFile.length + this.consensusFile.length + this.graphicFile.length + this.kmlFile.length + this.airportFile.length + this.envrFile.length;
    var requests=new Array(numFiles);
    var fileName = new Array(numFiles);
    var flg=new Array(numFiles);
    for (let j = 0; j < numFiles; j++) {
      let j2 = j;
      //var path = "../../assets/files/" + this.additionalFile[j].originalFileName; // for local testing
      
      if (j<this.additionalFile.length) {
        var path = API_URL + '/files/' +   this.submission.submissionId + "/" + this.additionalFile[j].originalFileName + '?auth=' + localStorage.token;
        fileName[j]=this.additionalFile[j].originalFileName;
      } else if (j<this.additionalFile.length + this.consensusFile.length){
        var index=j-this.additionalFile.length;
        var path = API_URL + "/files/" + this.submission.submissionId + "/" + this.consensusFile[index].originalFileName + '?auth=' + localStorage.token; 
        fileName[j]=this.consensusFile[index].originalFileName;
      } else if (j<this.additionalFile.length + this.consensusFile.length + this.graphicFile.length){
        var index=j-this.additionalFile.length- this.consensusFile.length;
        var path = API_URL + "/files/" + this.submission.submissionId + "/" + this.graphicFile[index].originalFileName + '?auth=' + localStorage.token; 
        fileName[j]=this.graphicFile[index].originalFileName;
      } else if (j<this.additionalFile.length + this.consensusFile.length + this.graphicFile.length + this.kmlFile.length){
        var index=j-this.additionalFile.length- this.graphicFile.length - this.consensusFile.length;
        var path = API_URL + "/files/" + this.submission.submissionId + "/" + this.kmlFile[index].originalFileName + '?auth=' + localStorage.token; 
        fileName[j]=this.kmlFile[index].originalFileName;
      } else if (j<this.additionalFile.length + this.consensusFile.length + this.graphicFile.length + this.kmlFile.length + this.airportFile.length){
        var index=j-this.additionalFile.length- this.graphicFile.length - this.kmlFile.length - this.consensusFile.length;
        var path = API_URL + "/files/" + this.submission.submissionId + "/" + this.airportFile[index].originalFileName + '?auth=' + localStorage.token; 
        fileName[j]=this.airportFile[index].originalFileName;
      } else {
        var index=j-this.additionalFile.length- this.consensusFile.length- this.graphicFile.length - this.kmlFile.length - this.airportFile.length;
        var path = API_URL + "/files/" + this.submission.submissionId + "/" + this.envrFile[index].originalFileName + '?auth=' + localStorage.token; 
        fileName[j]=this.envrFile[index].originalFileName;
      }
           
      requests[j] = new XMLHttpRequest();
      requests[j].open("GET", path);
      requests[j].responseType = "blob";
      requests[j].setRequestHeader("Content-type", "application/pdf");
      requests[j].onreadystatechange = function() {
        flg[j]=true;
        if (this.readyState == 4) {
          if (requests[j].response.size>1000) {   // don't add any files that aren't found into the zip file
            zip.file(fileName[j], requests[j].response);
          }
           if (j2===numFiles-1 && flg.every(Boolean)) { // only download the zip file once when all the responses are returned
           zip.generateAsync({ type: "blob" })
           .then(function (content) {
             saveAs(content, zipFileName);
           });
          }
        } 
      };
      requests[j].send();
    }
  }
}
