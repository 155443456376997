import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mt'
})

export class MtPipe implements PipeTransform {

  transform(val: any): string {
    if (val) {
      return val;
    }
    return '-';
  }

}
