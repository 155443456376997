import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SubmissionManageComponent } from '../../submission-manage/submission-manage.component';
import { UserService } from './user.service';

@Injectable()
export class CanDeActivateGuardService  {
  constructor(private userService: UserService) { }

  canDeactivate(component: SubmissionManageComponent): boolean {
    return !this.userService.isLoggedIn() || component.canDeactivate()
  }
}
