import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../shared/service/submission.service';
import { Submission } from 'app/shared/class/submission';
import { User } from 'app/shared/class/user';

@Component({
  selector: 'app-catex',
  templateUrl: './catex.component.html',
  styleUrls: ['./catex.component.css']
})
export class CatexComponent implements OnInit {

  submission: Submission;
  procedureDescription: string;
  designer: User;
  aptContact: User;
  catex: Array<any>;

  fullName: Array<any> = [];

  catexExplanation: string[] = [
    'Establishment or modification of helicopter routes that channel helicopter activity over major thoroughfares and do not have the potential to significantly increase noise over noise sensitive areas. (ATO, AVS)',
    'Establishment of new or revised air traffic control procedures conducted at 3,000 feet or more above ground level (AGL); procedures conducted below 3,000 feet AGL that  do not cause traffic to be routinely routed over noise sensitive areas; modifications to currently  approved procedures conducted below 3,000 feet AGL that do not significantly increase noise over  noise sensitive areas; and increases in minimum altitudes and landing minima. For modifications to  air traffic procedures at or above 3,000 feet AGL, the Noise Screening Tool (NST) or other FAA-approved  environmental screening methodology should be applied. (ATO, AVS)',
    'Implementation of procedures to respond to emergency air or ground safety needs, accidents, or natural events with no reasonably foreseeable long-term adverse impacts. (ATO)',
    'Publication of existing air traffic control procedures that do not essentially change existing tracks, create new tracks, change altitude, or change concentration of aircraft on these tracks. (ATO, AVS)',
    'Establishment of new procedures that routinely route aircraft over non-noise sensitive areas. (ATO, AVS)',
    'Establishment of jet routes and Federal airways'
  ];

  constructor(private subService: SubmissionService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(
      (r) => {
        this.subService.getSubmission(r.id).subscribe(
          (r) => {
            if (!r) {
              this.router.navigate(['/home']);
            } else {
              this.submission = r;
              this.designer = new User(r['ProcedureDesigner']);
              var val = r['AirportContact'];
              if (val) {
                this.aptContact = new User(val);
              }
              
              if (this.submission.procedureDescriptionForCATEX && this.submission.procedureDescriptionForCATEX.length > 0) {
                this.procedureDescription = this.submission.procedureDescriptionForCATEX;
              } else {
                this.procedureDescription = this.submission.procedureDescription;
              }

              if (r.ProcedureDetails.length>0){
                this.fullName[0]='1) ' + r.procedureName;
              } else{
                this.fullName[0]=r.procedureName;
              }

              for (var i = 0; i < r.ProcedureDetails.length; i++) {
                this.fullName[i+1]=(i+2) + ') ' + r.ProcedureDetails[i].procedureName;  
              } 

              this.catex = [];
              var code = ['h', 'i', 'j', 'k', 'p', 'b'];
              for (var i = 0; i < code.length; i++) {
                if (this.submission['IsCATEX_' + code[i]]) {
                  this.catex.push({
                    code: '5-6.5.' + code[i],
                    val: this.catexExplanation[i]
                  });
                }
              }
            }
          }
        )
      })
  }

}
