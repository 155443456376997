import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../shared/service/user.service';
import { AirportService } from '../shared/service/airport.service';
import { User } from '../shared/class/user';
import { Airport } from '../shared/class/airport';

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.css']
})
export class UserManageComponent implements OnInit {

  //#region variables
  currentUser: User;
  actionLabel = 'Update Account';
  isFromAdmin = false;
  isPortalAdmin = false;
  canAddUser = false;
  canReassignRole = false;
  isCreateAccount = false;
  isAccountRequest = false;
  isEditingOwnAccount = false;
  canSelectAirport = false;
  tempUser: User;
  showError = false;
  showAccReq = false;
  errorMessage: string;
  airports: Airport[];
  @ViewChild('aptCombo') aptCombo: ElementRef;

  availableRoles: Array<any> = [
    { type: 'ProcedureDesigner', str: 'Procedure Designer', ind: 0 },
    { type: 'AirportContact', str: 'Airport Contact', ind: 1 },
    { type: 'EnvironmentalSpecialist', str: 'Environmental Specialist', ind: 2 },
    { type: 'PortalAdmin', str: 'Portal Administrator', ind: 3 },
    { type: 'ServiceCenterAdmin', str: 'Service Center Administrator', ind: 4 },
    { type: 'ServiceCenterManager', str: 'Service Center Manager', ind: 5 },
    { type: 'ExternalSubmissionsAdmin', str: 'External Submissions Administrator', ind: 6 },
    { type: 'ExternalSubmissionsManager', str: 'External Submissions Manager', ind: 7 }
  ]

  serviceCenters: Array<any> = [
    { str: 'Central', code: 'C', id: 1 },
    { str: 'Eastern', code: 'E', id: 2 },
    { str: 'Western', code: 'W', id: 3 },
    { str: 'AFS', code: 'A', id: 4 },
    { str: 'External', code: 'X', id: 5 }
  ]

  userForm: FormGroup;
  email: FormControl;
  password: FormControl;
  confirmPassword: FormControl;
  roles: FormArray;
  serviceCenterCode: FormControl;
  selectedAirport: FormControl;
  selectedAirportReset: Airport;
  firstName: FormControl;
  lastName: FormControl;
  isActive: FormControl;

  disableE = false;
  disableC = false;
  disableW = false;
  //#endregion

  constructor(private userService: UserService,
    private airportService: AirportService,
    private router: Router,
    private route: ActivatedRoute) { }

  //#region init
  ngOnInit() {
    this.initForm();
    this.route.url.subscribe(
      (r) => {
        this.isPortalAdmin = this.userService.hasRole(['PortalAdmin', 'ServiceCenterAdmin']);
        this.canAddUser = this.userService.hasRole(['PortalAdmin', 'ServiceCenterAdmin', 'ExternalSubmissionsAdmin']);
        this.canReassignRole = this.userService.hasRole(['PortalAdmin', 'ServiceCenterAdmin', 'ServiceCenterManager', 'ExternalSubmissionsAdmin', 'ExternalSubmissionsManager']);

        if (r[0].path == 'user') {
          // from admin page
          this.isFromAdmin = true;
          if (r.length == 1) {
            if (this.canAddUser) {
              // create new user
              this.actionLabel = 'Create Account';
              this.isCreateAccount = true;
              this.setPasswordRequired();
              this.setFormRestriction();
              this.setEmailValidator();
            } else {
              this.router.navigate(['/home']);
            }
          } else {
            // edit existing user            
            var id = Number(r[1].path);
            // get user account for editing
            this.userService.getUser(id).subscribe(
              (r) => {
                if (r) {
                  this.currentUser = r;
                  this.resetForm();
                } else {
                  this.router.navigate(['/home']);
                }
              }
            );
          }
        } else if (r[0].path == 'requestaccount') {
          this.isAccountRequest = true;
          this.actionLabel = 'Create Account';
          this.isCreateAccount = true;
          this.setFormRestriction();
          this.setEmailValidator();
          this.setPasswordRequired() 
        } else {
          // user editing own account
          this.isEditingOwnAccount = true;
          this.currentUser = this.userService.getCurrentUser();
          this.resetForm();
          this.setPasswordRequired() 
        }
      }
    )
  }

  initForm() {
    this.email = new FormControl('');

    this.password = new FormControl();
    this.confirmPassword = new FormControl();

    this.roles = new FormArray(
      this.availableRoles.map(x => new FormControl()),
      this.rolesValidator
    );

    this.serviceCenterCode = new FormControl('');
    this.selectedAirport = new FormControl();

    this.firstName = new FormControl('', Validators.required);
    this.lastName = new FormControl('', Validators.required);
    this.isActive = new FormControl('');

    this.userForm = new FormGroup({
      email: this.email,
      password: this.password,
      confirmPassword: this.confirmPassword,
      roles: this.roles,
      serviceCenterCode: this.serviceCenterCode,
      selectedAirport: this.selectedAirport,
      firstName: this.firstName,
      lastName: this.lastName,
      isActive: this.isActive
    }, this.confirmPasswordValidator);
  }

  resetForm() {
    this.setEmailValidator();
    if (this.isEditingOwnAccount || this.isPortalAdmin) {
      this.canSelectAirport = true;
    }
    this.email.setValue(this.currentUser.email);
    this.firstName.setValue(this.currentUser.firstName);
    this.lastName.setValue(this.currentUser.lastName);
    this.isActive.setValue(this.currentUser.isActive ? 'yes' : 'no');

    if (this.currentUser.serviceCenterId) {
      var sc = this.serviceCenters.find(x => x.id == this.currentUser.serviceCenterId);
      this.serviceCenterCode.setValue(sc.code);
    }

    var rs = this.currentUser.roles;
    for (var i = 0; i < rs.length; i++) {
      var r = this.availableRoles.find(x => x.type == rs[i]);
      if (r) {
        this.roles.controls[r.ind].setValue(true);
      } else {
        this.roles.clearValidators();
        this.roles.updateValueAndValidity();
      }
    }

    if (this.currentUser.airportId) {
      this.airportService.getAirport(this.currentUser.airportId).subscribe(
        (r) => {
          this.airports = [r];
          this.selectedAirportReset = this.airports[0];
        });
    }

    this.setFormRestriction();

    // only portal admin can reassign sevice center and airport
    if (!this.isPortalAdmin) {
      this.serviceCenterCode.disable();
      this.selectedAirport.disable();
    }

    // only portal admin, sca, scm and esa, esm can reassign role
    if (!this.canReassignRole) {
      this.roles.disable();
    }

    if (this.isEditingOwnAccount) {
      this.selectedAirport.enable();
    }
  }

  setEmailValidator() {
    var vld = [
      Validators.required,
      Validators.pattern("^[A-Za-z0-9\-\_\.\']+@[A-Za-z0-9\-]+[\.][a-z]{2,4}$")
    ];

    if (this.userService.hasRole(['ExternalSubmissionsAdmin', 'ExternalSubmissionsManager']) ||
      (this.currentUser && !this.currentUser.email.endsWith('@faa.gov'))) {
      vld.push(Validators.pattern(".*(?<!@faa.gov)$"));
    }

    this.email.setValidators(vld);
  }

  setFormRestriction() {
    var allowedRoles = [];
    var allowedCenter;
    if (this.userService.hasRole(['ServiceCenterAdmin'])) {
      allowedRoles = allowedRoles.concat(['ProcedureDesigner', 'AirportContact', 'EnvironmentalSpecialist']);
      allowedCenter = this.userService.getCurrentUser().serviceCenterId;
    }

    if (this.userService.hasRole(['ServiceCenterManager'])) {
      allowedRoles.push('ServiceCenterAdmin');
      allowedCenter = this.userService.getCurrentUser().serviceCenterId;
    }

    if (this.userService.hasRole(['ExternalSubmissionsAdmin'])) {
      allowedRoles = allowedRoles.concat(['ProcedureDesigner', 'AirportContact', 'EnvironmentalSpecialist']);
      allowedCenter = 'none';
    }

    if (this.userService.hasRole(['ExternalSubmissionsManager'])) {
      allowedRoles.push('ExternalSubmissionsAdmin');
      allowedCenter = 'none';
    }

    if (this.userService.hasRole(['AccountRequestManager'])) {
      allowedRoles = allowedRoles.concat(['ProcedureDesigner', 'AirportContact', 'EnvironmentalSpecialist', 'ServiceCenterManager', 'ExternalSubmissionsManager']);
    }

    if (allowedRoles.length > 0) {
      var restrictedRole = this.availableRoles.filter(x => allowedRoles.indexOf(x.type) == -1);
      restrictedRole.map(x => {
        this.roles.controls[x.ind].disable();
      })
    }

    if (allowedCenter) {
      if (allowedCenter != 'none') {
        var sc = this.serviceCenters.find(x => x.id == allowedCenter);
        this.serviceCenterCode.setValue(sc.code);
      }
      this.serviceCenterCode.disable();
    }
  }

  setPasswordRequired() {
    this.password.setValidators([
      Validators.required,
      Validators.minLength(6)
    ]);

    this.confirmPassword.setValidators([
      Validators.required,
      Validators.minLength(6)
    ])
  }
  //#endregion

  //#region validators  
  rolesValidator(arr: FormArray) {
    if (arr.controls.map(x => x.value).filter(x => x == true).length == 0) {
      return { noRoles: true };
    }
    return null;
  }

  confirmPasswordValidator(form: FormGroup) {
    var cp = form.controls.confirmPassword;
    if (cp.value != form.controls.password.value) {
      if (!cp.errors) {
        cp.setErrors({
          matchPassword: {
            valid: false
          }
        });
      } else {
        cp.errors.matchPassword = { valid: false };
      }
    }
    return null;
  }

  isValid(cmp) {
    if (this[cmp].dirty || this[cmp].touched) {
      return this[cmp].invalid ? 'has-error' : 'has-success';
    }
    return null;
  }

  hasError(cmp) {
    return this[cmp].errors && (this[cmp].dirty || this[cmp].touched);
  }

  getErrorMsg(cmp) {
    var msg: string = '';
    var errors = this[cmp].errors;

    if (cmp == 'email') {
      if (errors.required) {
        msg = 'Email is required.';
      } else if (errors.pattern) {
        msg = 'Invalid email address.';
      }
    } else if (cmp == 'password' || cmp == 'confirmPassword') {
      if (errors.required) {
        msg = 'Password is required.';
      } else if (errors.minlength) {
        msg = 'Password must be have least 6 characters.';
      } else if (errors.matchPassword) {
        msg = 'Passwords do not match.';
      }
    } else if (cmp == 'roles') {
      msg = 'Must have at least 1 role.';
    } else if (cmp == 'firstName') {
      msg = 'First name is required.';
    } else if (cmp == 'lastName') {
      msg = 'Last name is required.';
    }
    return msg;
  }
  //#endregion

  //#region methods
  updateAirportList(event, cmp) {
    if (event.originalEvent.type == 'input') {
      this.airportService.getAirports(cmp.value).subscribe(
        (r) => {
          this.airports = r;
          cmp.show();
        });
    }

  }
  //#endregion

  //#region form
  onSubmit() {
    var obj = this.userForm.value;
    if (obj.password == null) {
      delete obj['password'];
    }
    delete obj['confirmPassword'];
    if (this.isCreateAccount || this.canReassignRole) {
      // obj.roles = obj.roles.map((x, i) => x ? this.availableRoles[i].type : null).filter(x => x != null);      
      obj.roles = this.roles.controls.map((x, i) => x.value ? this.availableRoles[i].type : null).filter(x => x != null);
    }

    if (obj.isActive) {
      obj.isActive = obj.isActive == 'yes';
    }

    if (this.selectedAirport.value) {
      obj.airportId = this.selectedAirport.value.airportId;
    }

    if (this.currentUser && this.currentUser.serviceCenterId) {
      obj.serviceCenterId = this.currentUser.serviceCenterId;
    }

    obj.serviceCenterCode = this.serviceCenterCode.value;
    if (obj.serviceCenterCode) {
      var sc = this.serviceCenters.find(x => x.code == obj.serviceCenterCode);
      obj.serviceCenterId = sc.id;
    }

    this.tempUser = new User(obj);
    if (!this.isCreateAccount) {
      this.tempUser.userId = this.currentUser.userId;
    }

    if (this.isAccountRequest) {
      this.tempUser.isActive = false;
    }

    var _this = this;
    this.userService.updateAccount(this.isCreateAccount, this.tempUser).subscribe(
      (r) => {
        if (this.isCreateAccount || this.canReassignRole) {
          // assign roles
          var userId;
          if (r['data']) {
            userId = Number(r['data'].userId);
          } else if (r['status'] == 'success') {
            userId = _this.currentUser.userId;
          }
          if (userId) {
            this.tempUser.userId = userId;

            this.userService.assignRoles(this.tempUser).subscribe(
              (r) => {
                if (r['data']) {
                  if (this.isAccountRequest) {
                    this.userService.logOut();
                    this.showAccReq = true;
                  }
                  else if (this.isCreateAccount || this.isFromAdmin) {
                    this.router.navigate(['/admin']);
                  } else if (this.isAccountRequest) {
                    this.userService.logOut();
                    this.router.navigate(['/login']);
                    this.showAccReq = true;
                  }
                  else {
                    this.router.navigate(['/home']);
                  }
                }
              },
              (err) => {
                this.errorMessage = err.error.message;
                this.showError = true;
              }
            )
          }
        } else {
          // no re-assign roles
          if (r['status'] == 'success') {
            this.userService.updateLoggedInUser();
            if (this.isFromAdmin) {
              this.router.navigate(['/admin']);
            } else {
              this.router.navigate(['/home']);
            }
          } else {
            this.errorMessage = r['status'];
            this.showError = true;
          }
        }
      },
      (err) => {
        this.errorMessage = err.error.message;
        this.showError = true;
      }
    );
  }

  closeMessage() {
    this.showError = false;
    if (this.isAccountRequest) {
      this.cancelEdit();
    }
  }

  cancelEdit() {
    if (this.isAccountRequest) {
      this.userService.logOut();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/home']);
    }
  }
  //#endregion
}
