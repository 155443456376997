import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Airport } from '../class/airport';
import { Navaid } from '../class/navaid';
import { Airway } from '../class/airway';

const API_URL = environment.apiUrl;

@Injectable()
export class AirportService {

  constructor(private http: HttpClient) { }

  // public getAirports(): Observable<Airport[]> {
  //   return this.http
  //     .get(API_URL + '/api/airports').pipe(
  //       map(r => {
  //         var dat = r['data'].items;
  //         return Object.keys(dat).map(function (key) {
  //           return new Airport(dat[key]);
  //         });
  //       })
  //     );
  // }

  public getAirports(str: string): Observable<Airport[]> {
    return this.http
      .get(API_URL + '/api/airports/get_by_code/' + str).pipe(
        map(r => {
          var dat = r['data'].items;
          return Object.keys(dat).map(function (key) {
            return new Airport(dat[key]);
          });
        })
      );
  }

  public getAirport(id: Number): Observable<Airport> {
    return this.http
      .get(API_URL + '/api/airports/' + id).pipe(
        map(r => {          
          var dat = r['data'].items;
          return new Airport(dat[0]);
        })
      );
  }

  public getNavaids(str: string): Observable<Navaid[]> {
    return this.http
      .get(API_URL + '/api/airports/navaid_by_code/' + str).pipe(
        map(r => {
          var dat = r['data'].items;
          return Object.keys(dat).map(function (key) {
            return new Navaid(dat[key]);
          });
        })
      );
  }

  public getNavaid(str: string): Observable<Navaid> {
    return this.http
      .get(API_URL + '/api/airports/navaid_by_uniqueId/' + str).pipe(
        map(r => {          
          var dat = r['data'].items;
          return new Navaid(dat);          
        })
      );
  }

  public getAirways(str: string): Observable<Airway[]> {
    return this.http
      .get(API_URL + '/api/airports/airway_by_code/' + str).pipe(
        map(r => {
          var dat = r['data'].items;
          return Object.keys(dat).map(function (key) {
            return new Airway(dat[key]);
          });
        })
      );
  }

  public getAirway(str: string): Observable<Airway> {
    return this.http
      .get(API_URL + '/api/airports/airway_by_uniqueId/' + str).pipe(
        map(r => {          
          var dat = r['data'].items;
          return new Airway(dat);          
        })
      );
  }
  

  public getNfdcFlag(): Observable<Object> {
    return this.http
      .get(API_URL + '/api/admin/nfdc-flag').pipe(
        map(r => {
          var updated = r['instance'].lastUpdated;
          updated = updated.length > 0 ? updated[0].updateDate : null;
          var resp = {
            autoUpdate: r['instance'].data.items.optionValue == 'true',
            lastUpdated: updated
          }
          return resp;
        })
      );
  }

  public setNfdcFlag(enable: boolean) {
    var params = {
      flag: enable
    };
    return this.http.put(API_URL + '/api/admin/nfdc-flag', params);
  }

  public runNfdcUpdate(): Observable<Object> {
    return this.http.post(API_URL + '/api/admin/run-nfdc', {});
  }

  public runNfdcNavaidsUpdate(): Observable<Object> {
    return this.http.post(API_URL + '/api/admin/run-nfdc-navaids', {});
  }

  public runNfdcAirwaysUpdate(): Observable<Object> {
    return this.http.post(API_URL + '/api/admin/run-nfdc-airways', {});
  }
}
