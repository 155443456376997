import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class CanActivateGuardService  {

  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('canActivate: ' + state.url);
    if (state.url == '/requestaccount') {
      return true;
    } else if (this.userService.isReqAccUser()) {
      this.userService.logOut();
      this.router.navigate(['/login']);
      return false;
    } else if (!this.userService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    } else if (state.url == '/') {
      this.router.navigate(['/home']);
    }
    return true;
  }
}
