export class User {
    email: string;
    password: string;
    status: string;
    roles: string[];
    firstName: string;
    lastName: string;
    isActive: boolean;
    userId: number;
    airportId: number;
    serviceCenterCode: string;
    serviceCenterId: number;
    name_email: string;

    constructor(values: Object = {}) {
        delete values['iat'];
        delete values['exp'];
        delete values['token'];
        values['name_email'] = values['lastName'] + ', ' + values['firstName'] + ' (' + values['email'] + ')';
        Object.assign(this, values);
    }
}
