// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.siteLogo>span {
    font-size: 1.75em;
}

.topNav>li {
    float: left;
    margin-left: 1em;
}

.topNav2 {
    list-style: none;
    padding: 0;
    text-align: right;
    clear: right;
    float: right;
    margin-top: 0.25em;
}

.topNav2>li {
    float: left;
    margin-left: 1em;
}

.topNav2>li>a {
    border-radius: 4px;
    line-height: 2;
    padding: 0 .5em;
    transition: background .15s linear;
}

.topNav2 a {
    color: #001F3F;
    display: block;
    text-decoration: none;
}

.topNav2 a:hover,
.topNav2 a:focus {
    background: #353535;
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/header/header.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,cAAc;IACd,qBAAqB;AACzB;;AAEA;;IAEI,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".siteLogo>span {\n    font-size: 1.75em;\n}\n\n.topNav>li {\n    float: left;\n    margin-left: 1em;\n}\n\n.topNav2 {\n    list-style: none;\n    padding: 0;\n    text-align: right;\n    clear: right;\n    float: right;\n    margin-top: 0.25em;\n}\n\n.topNav2>li {\n    float: left;\n    margin-left: 1em;\n}\n\n.topNav2>li>a {\n    border-radius: 4px;\n    line-height: 2;\n    padding: 0 .5em;\n    transition: background .15s linear;\n}\n\n.topNav2 a {\n    color: #001F3F;\n    display: block;\n    text-decoration: none;\n}\n\n.topNav2 a:hover,\n.topNav2 a:focus {\n    background: #353535;\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
