import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoordinateService {

  constructor() { }

  public parseTrackCoordinate(val) {
    var coord;
    if (val) {
      var flg = RegExp('[0-9]{6,7}(\.[0-9]{1,})?[NS][\/-][0-9]{6,7}(\.[0-9]{1,})?[EW]').test(val);
      // var flg = RegExp('[nsew]', 'gi').test(val);
      if (flg) {
        coord = this.parseDMS(val);
      } else {
        coord = this.parseDecimal(val);
      }
    }
    return coord;
  }

  private parseDecimal(data) {
    var coord = [];
    var dat = data.split('\n');
    if (dat.length > 1) {
      for (var i = 0; i < dat.length; i++) {
        var val = dat[i];
        if (val.length == 0) {
          if (i < dat.length - 1) {
            return null;
          }
        } else {
          var c = val.split(',');
          // latitude
          var x = c[0].split(' ');
          var n0 = Number(x[x.length - 1]);
          // longitude
          var n1 = Number(c[1]);
          if (c.length != 2 || isNaN(n0) || isNaN(n1)) {
            return null;
          }
          c = [n0, n1];
          coord.push(c);
        }
      }
    }
    return coord;
  }

  private parseDMS(data) {
    var coord = [];
    var dat = data.split('\n');
    if (dat.length > 1) {
      for (var i = 0; i < dat.length; i++) {
        var c = [];
        var val = dat[i];
        if (val.length == 0) {
          if (i < dat.length - 1) {
            return null;
          }
        } else {
          var delim = val.indexOf('-') > -1 ? '-' : '/';
          var x = val.split(delim);
          if (x.length == 2) {
            var label;
            for (var j = 0; j < 2; j++) {
              var str;
              if (j == 0) {
                var a = x[j];
                var b = a.split(' ');
                str = b[b.length - 1];
                if (b.length > 1) {
                  label = b.slice(0, b.length - 1).join(' ');
                }
              } else {
                str = x[j];
              }
              str = str.trim();
              var dir = str.charAt(str.length - 1).toUpperCase();
              var dms = str.slice(0, str.length - 1);
              var m = dms.length;
              var n = dms.indexOf('.');
              n = n > -1 ? n : m - 1;
              var ss = dms.slice(n - 2, m);
              var mm = dms.slice(n - 4, n - 2);
              var dd = dms.slice(0, n - 4);
              var mul = 1;
              if (j == 0) {
                mul = dir == 'N' ? 1 : -1;
              } else {
                mul = dir == 'E' ? 1 : -1;
              }
              var dec = mul * (Number(dd) + Number(mm) / 60 + Number(ss) / 3600);
              if (isNaN(dec)) {
                return null;
              }
              c.push(dec);
            }
            if (label) {
              c.push(label);
            }
          } else {
            return null;
          }
          coord.push(c);
        }
      }
    }    
    return coord;
  }
}
