export class Airport {
    airportId: number;
    aptCode: string;
    facilityName: string;
    region: string;
    latitude: number;
    longitude: number;
    state: string;
    label: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
