import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Submission } from '../class/submission';
import { provideImageKitLoader } from '@angular/common';

const API_URL = environment.apiUrl;

@Injectable()
export class SubmissionService {

  constructor(private http: HttpClient) { }

  public getSubmissions(): Observable<Submission[]> {
    return this.http
      .get(API_URL + '/api/submissions/').pipe(
        map(r => {
          var dat = r['data'].items;
          return Object.keys(dat).map(function (key) {
            var u = new Submission(dat[key]);
            if (u.requestId.startsWith('placeholder_')) {
              return null;
            } else {
              return u;
            }
          }).filter(x => x != null);
        })
      );
  }

  public getSubmission(id: number): Observable<Submission> {
    return this.http
      .get(API_URL + '/api/submissions/' + id).pipe(
        map(r => {
          if (r['data'].items.length == 0) {
            return null;
          } else {
            return new Submission(r['data'].items[0]);
          }
        })
      );
  }

  public createSubmission(sub: Submission): Observable<Object> {
    var params = JSON.parse(JSON.stringify(sub));
    return this.http.post(API_URL + '/api/submissions', params);
  }

  public updateSubmission(sub: Submission): Observable<Object> {
    var params = JSON.parse(JSON.stringify(sub, (k, v) => v === undefined ? null : v));
    return this.http.put(API_URL + '/api/submissions/' + sub.submissionId, params);
  }

  public addComment(id: number, comment: string, isRevision: boolean): Observable<Object> {
    var params = {
      'submissionId': id,
      'subject': 'subject',
      'comment': comment,
      'isRevisionLog': isRevision
    }
    return this.http.post(API_URL + '/api/submission/comments', params);
  }

  public updateDetails(pid: number, id: number, procedureName: string, procedureDescription: string, hidden: number): Observable<Object> {
    var params = {
      'ProcedureId': pid,
      'submissionId': id,
      'procedureName': procedureName,
      'procedureDescription': procedureDescription,
      'hidden': hidden,
    }
    return this.http.put(API_URL + '/api/procedureDetails/' + pid, params);
  }

  public addDetails(id: number, procedureName: string, procedureDescription: string, hidden: number): Observable<Object> {
    var params = {
      'submissionId': id,
      'procedureName': procedureName,
      'procedureDescription': procedureDescription,
      'hidden': hidden,
    }
    return this.http.post(API_URL + '/api/procedureDetails', params);
  }

  public uploadFile(subId: Number, files: File[], fid: string): Observable<Object> {
    var formData: FormData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].name);
      formData.append(files[i].name, fid);
    }

    return this.http.post(API_URL + '/api/submission/files/' + subId, formData);
  }

  public deleteFile(id: Number): Observable<Object> {
    return this.http.delete(API_URL + '/api/submission/files/' + id);
  }

  public readFile(path: string) {
    var url = '/' + path + '?auth=' + localStorage.token;
    // var url = '/' + path; // for local testing
    return this.http.get(API_URL + url, { responseType: 'text' });
  }
}
